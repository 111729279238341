import React, { Fragment } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalFooter, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { FirstName, LastName } from "../../../../constant";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
const Step1 = (props) => {
    const { formInfo, setInfo } = props

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [socialReason, setSocialReason] = useState('')
    const [fantasyName, setFantasyName] = useState('')
    const [phone, setPhone] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [initials, setInitials] = useState('')
    const [neighborhoodSubscription, setNeighborhoodSub] = useState('')
    const [stateSubscription, setStateSub] = useState('')

    const [loadingInputs, setLoadingInputs] = useState(false)

    const [errors, setErrors] = useState({})

    const nextStep = () => {
        if (name === '' && formInfo.name === '') {
            setErrors({
                name: 'Nome é obrigatório!'
            })
        } else {
            setErrors({})
            const temp = {
                name: name !== '' ? name : formInfo.name,
                email: email !== '' ? email : formInfo.email,
                phone: phone !== '' ? phone : formInfo.phone,
                socialReason: socialReason !== '' ? socialReason : formInfo.socialReason,
                fantasyName: fantasyName !== '' ? fantasyName : formInfo.fantasyName,
                cnpj: cnpj !== '' ? cnpj : formInfo.cnpj,
                initials: initials !== '' ? initials : formInfo.initials,
                neighborhoodSubscription: neighborhoodSubscription !== '' ? neighborhoodSubscription : formInfo.neighborhoodSubscription,
                stateSubscription: stateSubscription !== '' ? stateSubscription : formInfo.stateSubscription,
            }
            setInfo(temp)
        }
    }

    const getCnpj = async (data) => {
        setLoadingInputs(true)
        setErrors({})

        const name = data.replace(/[^a-zA-Z0-9 ]/g, "")
        await axios.get(`https://publica.cnpj.ws/cnpj/${name}`).
            then(response => {
                setSocialReason(response.data.razao_social)
                setPhone(response.data.estabelecimento.telefone1)
                setFantasyName(response.data.estabelecimento.nome_fantasia !== null ? response.data.estabelecimento.nome_fantasia : '')
                setLoadingInputs(false)
            }).catch(error => {
                setLoadingInputs(false)
                setErrors({
                    cnpj: error.response.data.detalhes
                })
            })
    }


    return (
        <Fragment>
            <Container fluid={true}>
                <Form>
                    <Card className="card-absolute">
                        <CardHeader className="bg-primary">
                            <h5>{'Passo 1'}</h5>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Label className="col-form-label">{"CNPJ:"}</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        maxLength="18"
                                        onChange={(e) => {
                                            setCnpj(e.target.value)
                                            if (e.target.value.length === 14 || e.target.value.length === 18) {
                                                getCnpj(e.target.value)
                                            }
                                        }}
                                        defaultValue={formInfo.cnpj} />
                                    <span className="font-danger">{errors.cnpj}</span>
                                </Col>
                                <Col>
                                    <Label className="col-form-label">{"Nome:"}</Label>
                                    <Input className="form-control" type="text" onChange={(e) => setName(e.target.value)} defaultValue={name !== '' ? name : formInfo.name} />
                                    <span className="font-danger">{errors.name}</span>
                                </Col>
                            </Row>
                            <Row>
                                {loadingInputs ?
                                    <Col>
                                        <div className="loader-box">
                                            <div className="loader-4"></div>
                                        </div>
                                    </Col>
                                    :
                                    <Col>
                                        <Label className="col-form-label">{"Razão Social:"}</Label>
                                        <Input className="form-control" type="text" onChange={(e) => setSocialReason(e.target.value)} defaultValue={socialReason !== '' ? socialReason : formInfo.socialReason} />
                                    </Col>
                                }
                                {loadingInputs ?
                                    <Col>
                                        <div className="loader-box">
                                            <div className="loader-4"></div>
                                        </div>
                                    </Col>
                                    :
                                    <Col>
                                        <Label className="col-form-label">{"Nome Fantasia:"}</Label>
                                        <Input className="form-control" type="text" onChange={(e) => setFantasyName(e.target.value)} defaultValue={fantasyName !== '' ? fantasyName : formInfo.fantasyName} />
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="col-form-label">{"Email:"}</Label>
                                    <Input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} defaultValue={email !== '' ? email : formInfo.email} />
                                </Col>
                                {loadingInputs ?
                                    <Col>
                                        <div className="loader-box">
                                            <div className="loader-4"></div>
                                        </div>
                                    </Col>
                                    :
                                    <Col>
                                        <Label className="col-form-label">{"Telefone:"}</Label>
                                        <Input className="form-control" type="tel" onChange={(e) => setPhone(e.target.value)} defaultValue={phone !== '' ? phone : formInfo.phone} />
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="col-form-label">{"Inscrição Municipal:"}</Label>
                                    <Input className="form-control" type="text" onChange={(e) => setNeighborhoodSub(e.target.value)} defaultValue={formInfo.neighborhoodSubscription} />
                                </Col>
                                <Col>
                                    <Label className="col-form-label">{"Inscrição Estadual:"}</Label>
                                    <Input className="form-control" type="text" onChange={(e) => setStateSub(e.target.value)} defaultValue={formInfo.stateSubscription} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="col-form-label">{"Sigla:"}</Label>
                                    <Input className="form-control" type="text" onChange={(e) => setInitials(e.target.value)} defaultValue={formInfo.initials} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Form>
                <ModalFooter>
                    <Button color="primary" className="float-right" onClick={() => nextStep()}>Próximo</Button>
                </ModalFooter>
            </Container>
        </Fragment>
    );
};

export default Step1;