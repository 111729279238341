import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Breadcrumb, Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Dropdown, DropdownItem, DropdownMenu, FormGroup, Input, Label, Modal, Row, Table } from "reactstrap";
import axios from "axios";
import { authHeader } from "../../services/fack.backend";
import CreatePatient from "../patient/form/create/createPatient";
import { Typeahead } from 'react-bootstrap-typeahead';
import Swal from "sweetalert2";
import { classes } from '../../data/layouts';
import { useParams } from "react-router";

const Appointment = (props) => {
    const procedureTypeHeadRef = useRef(null);
    const params = useParams();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingInputPatient, setIsLoadingInputPatient] = useState(true);
    const [isLoadingInputInsurances, setIsLoadingInputInsurances] = useState(false);
    const [isLoadingInputEmergency, setIsLoadingInputEmergency] = useState(false);
    const [isLoadingInputClinic, setIsLoadingInputClinic] = useState(true);
    const [isLoadingInputAcomodation, setIsLoadingInputAcomodation] = useState(false);
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const [isLoadingInputDoctor, setIsLoadingInputDoctor] = useState(false);
    const [isLoadingInputSpecialties, setIsLoadingInputSpecialties] = useState(false);
    const [isLoadingInputDegree, setIsLoadingInputDegree] = useState(false);
    const [isLoadingInputProcedure, setIsLoadingInputProcedure] = useState(false);
    const [isLoadingTotal, setIsLoadingTotal] = useState(false);


    const [isOpenCreatePatient, setIsOpenCreatePatient] = useState(false)

    const [appointment, setAppoiment] = useState({
        id: null,
        date: null,
        time: null,
        insurance_id: null,
        emergency_id: null,
        acomodation_id: null,
        appointmentType: null,
        observation: null,
        guideNumber: null,
        specialty_id: null,
        specialty_name: null,
        degree_id: null,
        patient_id: null,
        doctor_id: null,
        clinic_id: null,
        procedures: [],
        total: 0,
        totalDescription: null, // montagem com base na descrição dos calculos.
        files: []
    });

    // Utilizado para rota de calculo, envia e resposta, e ao salvar appoiment pega alguns dados desse State.
    const [cart, setCart] = useState({
        total: 0,
        emergency_percentage: 0,
        degree_percentage: 0,
        acomodation_id: null,
        health_insurance_id: null,
        acomodation_value: 0,
        acomodation_description: null,
        procedures: []
    })

    // Listas
    const [patients, setPatients] = useState([])
    const [health_insurances, setHealthInsurances] = useState([])

    const [clinics, setClinics] = useState([])
    const [doctors, setDoctors] = useState([])
    const [degrees, setDegrees] = useState([])
    const [procedures, setProcedures] = useState([])
    const [acomodations, setAcomodations] = useState([])
    const [files, setFiles] = useState([])
    const [error, setError] = useState('')

    useEffect(async () => {
        if (params.id)
            await fetchAppointment(params.id)

        await fetchPatients();
        await fetchClinics();
        await fetchDoctors();
        await fetchDegrees();

        setIsLoading(false);
    }, [])

    const onCloseCreatePatient = () => {
        setIsOpenCreatePatient(false)
    }

    const openCreatePatient = () => {
        setIsOpenCreatePatient(true)
    }

    const fetchAppointment = async (id) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/${id}`, { headers: authHeader() })
        setAppoiment({
            id: id,
            date: response.data.date,
            time: response.data.time,
            insurance_id: response.data.insurance_id,
            emergency_id: response.data.emergency_id,
            acomodation_id: response.data.acomodation_id,
            appointmentType: response.data.appointmentType,
            observation: response.data.observation,
            guideNumber: response.data.guideNumber,
            specialty_id: response.data.specialty_id,
            specialty_name: response.data.specialty ? response.data.specialty.name : '',
            degree_id: response.data.degree_id,
            patient_id: response.data.patient_id,
            doctor_id: response.data.doctor_id,
            clinic_id: response.data.clinic_id,
            procedures: response.data.procedures,
            total: response.data.total,
            totalDescription: response.data.totalDescription,
            files: response.data.files
        })
        setCart({
            total: response.data.total,
            emergency_percentage: response.data.emergency?.percentage,
            degree_percentage: response.data.degree?.percentage,
            acomodation_id: response.data.acomodation_id,
            health_insurance_id: response.data.insurance_id,
            acomodation_value: response.data.acomodation?.health_insurance_acomodation?.value,
            procedures: response.data.procedures
        })
        fetchHealthInsurancesByPatient(response.data.patient_id, true)
        fetchAcomodationsByClinicId(response.data.clinic_id, true)
    }

    const fetchClinics = async () => {
        setIsLoadingInputClinic(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/clinic/getClinics`, { headers: authHeader() });
        setClinics(response.data)
        setIsLoadingInputClinic(false)
    }

    const fetchPatients = async () => {
        setIsLoadingInputPatient(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/patient/all`, { headers: authHeader() })

        setPatients(response.data)
        setIsLoadingInputPatient(false)
    }

    const fetchDoctors = async () => {
        setIsLoadingInputDoctor(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/doctor/all`, { headers: authHeader() })
        const sortedDoctors = response.data.sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        setDoctors(sortedDoctors)
        setIsLoadingInputDoctor(false)
    }

    const fetchDegrees = async () => {
        setIsLoadingInputDegree(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/participationDegree`, { headers: authHeader() })
        setDegrees(response.data)
        setIsLoadingInputDegree(false)
    }

    const fetchHealthInsurancesByPatient = async (patientId, isEdit = false) => {
        setIsLoadingInputInsurances(true)

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/patientInsurance/getInsurances/${patientId}`, { headers: authHeader() });

        if (response.data.length > 0) {
            setHealthInsurances(response.data)
            if (!isEdit) {
                setAppoiment({ ...appointment, insurance_id: response.data[0].health_insurance.id, patient_id: patientId })
                setCart({ ...cart, health_insurance_id: response.data[0].health_insurance.id })
            }
            fetchProceduresByInsuranceId(response.data[0].health_insurance.id)
        }

        setIsLoadingInputInsurances(false)
    }

    const fetchAcomodationsByClinicId = async (clinicId, isEdit = false) => {
        setIsLoadingInputAcomodation(true)

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/clinicAcomodation/getAcomodations/${clinicId}`, { headers: authHeader() });

        setAcomodations(response.data)

        if (!isEdit) {
            setAppoiment({ ...appointment, clinic_id: clinicId, acomodation_id: response.data[0].id })
            setCart({ ...cart, acomodation_id: response.data[0].id })
        }

        setIsLoadingInputAcomodation(false)
    }

    const fetchSpecialtiesByDoctorId = async (doctorId) => {
        setIsLoadingInputSpecialties(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/doctor-specialty/${doctorId}`, { headers: authHeader() })
        setAppoiment({ ...appointment, specialty_id: response.data.id, doctor_id: doctorId, specialty_name: response.data.name })
        setIsLoadingInputSpecialties(false)
    }

    const fetchProceduresByInsuranceId = async (insuranceId) => {
        setIsLoadingInputProcedure(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2/procedures/${insuranceId}/by-insurance`, { headers: authHeader() })
        setProcedures(response.data)
        setIsLoadingInputProcedure(false)
    }

    const emergencyToggle = async () => {
        setErrors({})
        setIsLoadingInputEmergency(true)

        if (appointment.emergency_id) {
            appointment.emergency_id = null
            cart.emergency_percentage = 0
            await calculate();
            setIsLoadingInputEmergency(false)
            return;
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/getEmergency`, {
            date: appointment.date,
            insurance_id: appointment.insurance_id
        }, { headers: authHeader() });

        if (response.data.id) {
            appointment.emergency_id = response.data.id
            cart.emergency_percentage = response.data.percentage;

            if (cart.procedures.length > 0)
                await calculate();

            setIsLoadingInputEmergency(false);
            return;
        } else {
            setErrors({ emergency: 'Não existe valor de emergência cadastrado para este convênio.' })
            setIsLoadingInputEmergency(false);
            return;
        }


    }

    const handleFiles = async (event) => {
        setIsLoadingFiles(true)

        let temp = appointment.files
        let contextFiles = event.target.files

        const formData = new FormData();

        Object.values(contextFiles).map((file, index) => {
            formData.append(`files[${index}]`, file);
        })

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/attachment`, formData, { headers: authHeader() })

        response.data.map(file => {
            temp = temp.filter(f => f.originalName !== file.originalName);
            temp.push(file);
        })

        setAppoiment({ ...appointment, files: temp })
        setIsLoadingFiles(false)
    }

    const calculate = async () => {
        setIsLoadingTotal(true)
        setError('')
        if (params.id) {
            const temp = []
            cart.procedures.map(value => {
                temp.push({
                    procedure_id: value.procedure_id,
                    procedure_percentage: value.procedure_percentage !== undefined ? value.procedure_percentage : 0,
                    index: value.index !== undefined ? value.index : cart.procedures.length - 1
                })
            })

            cart.procedures = temp

            await axios.post(`${process.env.REACT_APP_API_URL}/api/v2/appointment/calculate`, cart, { headers: authHeader() })
                .then(response => {
                    setCart(response.data)
                    setAppoiment({ ...appointment, total: response.data.total })
                    setIsLoadingTotal(false)
                    return
                }).catch(err => {
                    setError(err.response.data.message)
                    setIsLoadingTotal(false)
                    return
                });
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/api/v2/appointment/calculate`, cart, { headers: authHeader() })
            .then(response => {
                setCart(response.data)
                setAppoiment({ ...appointment, total: response.data.total })
                setIsLoadingTotal(false)
            }).catch(err => {
                setError(err.response.data.message)
                cart.procedures = cart.procedures.filter(procedure => procedure.index != undefined)
                setIsLoadingTotal(false)
            });
    }

    const save = async () => {
        let timerInterval;
        Swal.fire({
            title: 'Agendamento sendo salvo!',
            html: 'Por Favor Aguarde!',
            timer: 5000,
            timerProgressBar: false,
            didOpen: () => {
                Swal.showLoading()
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        })

        if (appointment.id)
            return update(appointment);

        create(appointment)
    }

    const create = async () => {
        appointment.procedures = []
        cart.procedures.map(value => appointment.procedures.push(value))
        await axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/create`, appointment, { headers: authHeader() })
            .then(res => {
                Swal.fire('Sucesso', 'Agendamento registrado com Sucesso', 'success')
                window.location.href = `${process.env.PUBLIC_URL}/app/appointment/${layout}`;
            }).catch(err => {
                Swal.fire('Error', err.response.data, 'error');
            })
    }

    const update = async (appoiment) => {
        const temp = []
        cart.procedures.map(value => temp.push(value))
        appointment.procedures = temp
        await axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/edit/${appoiment.id}`, appointment, { headers: authHeader() })
            .then(res => {
                Swal.fire('Sucesso', 'Agendamento editado com Sucesso', 'success')
            }).catch(err => {
                Swal.fire('Error', err.response.data, 'error');
            })
    }

    const duplicateAppointment = async (degree) => {
        if (params.id) {
            const temp = []
            cart.procedures.map(value => temp.push(value))
            appointment.procedures = temp
        } else {
            appointment.procedures = []
            cart.procedures.map(value => appointment.procedures.push(value))
        }
        let timerInterval;

        if (cart.procedures.length === 0) {
            Swal.fire('Error', 'Selecione ao menos um Procedimento para salvar o Agendamento', 'error');
            return
        }

        Swal.fire({
            title: 'Agendamento sendo salvo!',
            html: 'Por Favor Aguarde!',
            timer: 5000,
            timerProgressBar: false,
            didOpen: () => {
                Swal.showLoading()
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        })

        await axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/create`, appointment, { headers: authHeader() })
            .then(res => {
                Swal.fire('Sucesso', 'Agendamento registrado com Sucesso', 'success')
                cart.degree_percentage = degree.percentage
                appointment.degree_id = degree.id
                calculate()
            }).catch(err => {
                Swal.fire('Error', err.response.data, 'error');
            })
    }

    const deleteFile = async (file_prop) => {
        setIsLoadingFiles(true)

        const temp = []
        appointment.files.map(file => {
            if (file !== file_prop) {
                temp.push(file)
            }
        })

        if (file_prop.attachment_id) {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/appointment/attachment/${file_prop.attachment_id}`, { headers: authHeader() })
                .then(res => {
                    setAppoiment({ ...appointment, files: temp })
                    setIsLoadingFiles(false)
                })
            return
        }
        setAppoiment({ ...appointment, files: temp })
        setIsLoadingFiles(false)
    }

    function formatCurrency(number) {
        const formattedNumber = parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return `R$: ${formattedNumber}`;
    }

    return (
        <React.Fragment>
            <Container fluid={true}>

                <Modal isOpen={isOpenCreatePatient} size="lg">
                    <CreatePatient
                        onCloseModal={onCloseCreatePatient}
                        fetchPatients={fetchPatients}
                    />
                </Modal>
                {isLoading ?
                    <p>Carregando...</p>
                    :
                    <>
                        <div className="row">
                            <Col sm="12" md="6">
                                <Card>
                                    <CardHeader>
                                        <h5>{"Agendamento"}</h5>
                                        <span> {"Registre um Agendamento"}</span>
                                    </CardHeader>
                                    <CardBody>
                                        <h6 className="mb-4">Informações do Paciente</h6>
                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label">Paciente:</Label>
                                            {isLoadingInputPatient ?
                                                <Col>
                                                    <div className="loader-box">
                                                        <div className="loader-4"></div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col sm="9">
                                                    <div className="input-group">
                                                        <Typeahead
                                                            id="patient"
                                                            labelKey="name"
                                                            multiple={false}
                                                            options={patients}
                                                            selected={appointment.patient_id ? [patients.find(patient => patient.id == appointment.patient_id)] : []}
                                                            placeholder="Escolha um Paciente"
                                                            onChange={(patient) => {
                                                                if (patient.length > 0) {
                                                                    fetchHealthInsurancesByPatient(patient[0].id)
                                                                    return;
                                                                }

                                                                setAppoiment({ ...appointment, patient_id: null, insurance_id: null, clinic_id: null, acomodation_id: null })
                                                                setHealthInsurances([])
                                                            }}
                                                        />
                                                        <Button color="success" onClick={() => openCreatePatient()} ><i className="fa fa-plus"></i></Button>
                                                    </div>

                                                </Col>
                                            }
                                        </FormGroup>

                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label">Convênio:</Label>
                                            {isLoadingInputInsurances ?
                                                <Col>
                                                    <div className="loader-box">
                                                        <div className="loader-4"></div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col sm="9">
                                                    {health_insurances.length > 0 ?
                                                        <Input className="form-control" type="select" onChange={(e) => {
                                                            setAppoiment({ ...appointment, insurance_id: e.target.value })
                                                            setCart({ ...cart, health_insurance_id: e.target.value })

                                                            fetchProceduresByInsuranceId(e.target.value);
                                                        }}>
                                                            {health_insurances.map((insurance, index) =>
                                                                cart.health_insurance_id == insurance.health_insurance.id
                                                                    ?
                                                                    <option key={index} selected name={insurance.health_insurance.id} value={insurance.health_insurance.id}>{insurance.health_insurance.name}</option>
                                                                    :
                                                                    <option key={index} name={insurance.health_insurance.id} value={insurance.health_insurance.id}>{insurance.health_insurance.name}</option>
                                                            )}
                                                        </Input>
                                                        :
                                                        <Input className="form-control" type="select" disabled />
                                                    }
                                                </Col>
                                            }
                                        </FormGroup>

                                        <h6 className="mt-4 mb-4">Informações do Agendamento</h6>

                                        <div className="section-blur">
                                            {!appointment.insurance_id && <div className="section-blur-overlay">Informe um convênio para desbloquear</div>}

                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">Data:</Label>
                                                <Col sm="9">
                                                    <Input className="form-control digits" type="date" defaultValue={appointment.date ? appointment.date : null} onChange={(e) => {
                                                        setAppoiment({ ...appointment, date: e.target.value })
                                                    }} />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">Hora:</Label>
                                                <Col sm="9">
                                                    <Input className="form-control digits" type="time" defaultValue={appointment.time ? appointment.time : null} onBlur={(e) => {
                                                        setAppoiment({ ...appointment, time: e.target.value })
                                                    }} />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup className="row">
                                                {isLoadingInputEmergency
                                                    ?
                                                    <div className="loader-box">
                                                        <div className="loader-4"></div>
                                                    </div>

                                                    :

                                                    !appointment.date || !appointment.time ?

                                                        <div className="checkbox checkbox-solid-primary disabled ">
                                                            <Input id="solid3" type="checkbox" disabled />
                                                            <Label for="solid3">Emergência</Label>
                                                            <br />
                                                            <span className="font-danger">{errors.emergency}</span>
                                                        </div>
                                                        :

                                                        <div className="checkbox checkbox-solid-primary ">
                                                            <Input id="solid3" type="checkbox" checked={appointment.emergency_id ? true : false} onChange={(e) => emergencyToggle()} />
                                                            <Label for="solid3">Emergência</Label>
                                                            <br />
                                                            <span className="font-danger">{errors.emergency}</span>
                                                        </div>
                                                }
                                            </FormGroup>

                                        </div>

                                        <h6 className="mt-4 mb-4">Informações do Contratado</h6>

                                        <div className="section-blur">
                                            {!appointment.insurance_id && <div className="section-blur-overlay"></div>}

                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">Clínica:</Label>
                                                {isLoadingInputClinic
                                                    ?
                                                    <Col>
                                                        <div className="loader-box">
                                                            <div className="loader-4"></div>
                                                        </div>
                                                    </Col>
                                                    :
                                                    <Col sm="9">
                                                        <Typeahead
                                                            id="clinics"
                                                            labelKey={"name"}
                                                            multiple={false}
                                                            options={clinics}
                                                            selected={appointment.clinic_id ? [clinics.find(clinic => clinic.id === parseInt(appointment.clinic_id))] : []}
                                                            placeholder="Escolha uma Clínica"
                                                            onChange={(clinic) => {
                                                                if (clinic.length > 0) {
                                                                    fetchAcomodationsByClinicId(clinic[0].id)
                                                                    return;
                                                                }

                                                                setAppoiment({ ...appointment, clinic_id: null, acomodation_id: null })
                                                                setCart({ ...cart, acomodation_id: null, acomodation_value: 0 })
                                                            }}
                                                            clear
                                                        />
                                                    </Col>
                                                }
                                            </FormGroup>
                                        </div>

                                        <div className="section-blur">
                                            {!appointment.clinic_id && <div className="section-blur-overlay"></div>}

                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">Acomodação:</Label>
                                                {isLoadingInputAcomodation
                                                    ?
                                                    <Col>
                                                        <div className="loader-box">
                                                            <div className="loader-4"></div>
                                                        </div>
                                                    </Col>
                                                    :
                                                    <Col sm="9">
                                                        <Input className="form-control" type="select" disabled={acomodations.length > 0 ? false : true} onChange={(e) => {
                                                            appointment.acomodation_id = e.target.value
                                                            cart.acomodation_id = e.target.value
                                                            calculate()
                                                        }}>
                                                            <option disabled selected value></option>
                                                            {acomodations.map((acomodation, index) => (
                                                                appointment.acomodation_id == acomodation.id
                                                                    ?
                                                                    <option key={index} selected value={acomodation.id}>{acomodation.name}</option>
                                                                    :
                                                                    <option key={index} value={acomodation.id}>{acomodation.name}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                }
                                            </FormGroup>
                                        </div>

                                        <FormGroup className="row mt-4">
                                            <Label className="col-sm-3 col-form-label">Tipo:</Label>
                                            <Col sm="9">
                                                <div className="radio radio-primary">
                                                    <Input id="radioinline1" type="radio" name="radio1" checked={appointment.appointmentType == "Consulta"} value="Consulta" onChange={(e) => {
                                                        setAppoiment({ ...appointment, appointmentType: e.target.value })
                                                    }} />
                                                    <Label className="mb-0" for="radioinline1">Consulta</Label>
                                                </div>
                                                <div className="radio radio-primary">
                                                    <Input id="radioinline2" type="radio" name="radio1" checked={appointment.appointmentType == "Procedimento"} value="Procedimento" onChange={(e) => {
                                                        setAppoiment({ ...appointment, appointmentType: e.target.value })
                                                    }} />
                                                    <Label className="mb-0" for="radioinline2">Procedimento</Label>
                                                </div>
                                                <br />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="12" md="6">
                                <Card>
                                    <CardHeader>
                                        <h5>{"Informações gerais"}</h5>
                                        <span> {"Dados de procedimento, documento entre outros"}</span>
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label">Observações:</Label>
                                            <Col sm="9">
                                                <textarea className="form-control" rows="5" cols="5" defaultValue={appointment.observation} onChange={(e) => {
                                                    setAppoiment({ ...appointment, observation: e.target.value });
                                                }}></textarea>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label">{"Anexos:"}</Label>
                                            <Col sm="9">
                                                <Input className="form-control" multiple type="file" onChange={(e) => handleFiles(e)} />
                                            </Col>
                                            {isLoadingFiles ?
                                                <div className="loader-box">
                                                    <div className="loader-4"></div>
                                                </div>
                                                :
                                                appointment.files.map(file => (
                                                    file.attachment_id
                                                        ?
                                                        <p className="txt-primary"><a href={`${process.env.REACT_APP_API_URL}${file.attachment.path}`} target="_blank">{`${file.attachment.originalName}`}</a><i style={{ cursor: "pointer" }} className="fa fa-trash" onClick={(e) => deleteFile(file)}></i></p>
                                                        :
                                                        <p className="txt-primary">{`${file.originalName}`}<i style={{ cursor: "pointer" }} className="fa fa-trash" onClick={(e) => deleteFile(file)}></i></p>
                                                ))}
                                        </FormGroup>
                                    </CardBody>

                                    <CardBody>
                                        <h6 className="mb-4">Informações do Executor</h6>

                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label">{"N° Guia:"}</Label>
                                            <Col sm="9">
                                                <Input className="form-control digits" type="text" defaultValue={appointment.guideNumber ? appointment.guideNumber : ''} onChange={(e) => {
                                                    setAppoiment({ ...appointment, guideNumber: e.target.value })
                                                }} />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label">Médico:</Label>
                                            {isLoadingInputDoctor
                                                ?
                                                <Col>
                                                    <div className="loader-box">
                                                        <div className="loader-4"></div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col sm="9">
                                                    <Typeahead
                                                        id="doctor"
                                                        labelKey={"name"}
                                                        multiple={false}
                                                        options={doctors}
                                                        placeholder="Escolha um Médico"
                                                        selected={appointment.doctor_id ? [doctors.find(doctor => doctor.id === parseInt(appointment.doctor_id))] : []}
                                                        onChange={(doctor) => {

                                                            if (doctor.length > 0) {
                                                                fetchSpecialtiesByDoctorId(doctor[0].id)
                                                                return;
                                                            }
                                                            setAppoiment({ ...appointment, specialty: null, doctor_id: null })
                                                        }}
                                                    />
                                                </Col>
                                            }
                                        </FormGroup>

                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label">Especialidade:</Label>
                                            {isLoadingInputSpecialties
                                                ?
                                                <Col>
                                                    <div className="loader-box">
                                                        <div className="loader-4"></div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col sm="9">
                                                    <Input className="form-control" type="text" disabled={true} value={appointment.specialty_id ? appointment.specialty_name : ''} />
                                                </Col>
                                            }
                                        </FormGroup>

                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label">Grau de Participação:</Label>
                                            {isLoadingInputDegree
                                                ?
                                                <Col>
                                                    <div className="loader-box">
                                                        <div className="loader-4"></div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col sm="9">
                                                    <Input className="form-control" type="select" disabled={degrees.length > 0 ? false : true} onChange={(e) => {
                                                        appointment.degree_id = e.target.value
                                                        setAppoiment({ ...appointment, degree_id: e.target.value })
                                                        cart.degree_percentage = degrees.filter(degree => degree.id == e.target.value)[0].percentage
                                                        if (cart.procedures.length > 0)
                                                            calculate()
                                                    }}>
                                                        <option disabled selected value></option>
                                                        {degrees.map((degree, index) => (
                                                            appointment.degree_id == degree.id
                                                                ?
                                                                <option key={index} selected value={degree.id}>{degree.degree}</option>
                                                                :
                                                                <option key={index} value={degree.id}>{degree.degree}</option>
                                                        ))}
                                                    </Input>
                                                </Col>
                                            }
                                        </FormGroup>

                                        <div className="section-blur">
                                            {(!appointment.insurance_id || !appointment.doctor_id || !appointment.degree_id) && <div className="section-blur-overlay"></div>}
                                            <FormGroup className="row">
                                                {isLoadingInputProcedure
                                                    ?
                                                    <Col>
                                                        <div className="loader-box">
                                                            <div className="loader-4"></div>
                                                        </div>
                                                    </Col>
                                                    :
                                                    <Col sm="12">
                                                        <Typeahead
                                                            id="procedure"
                                                            labelKey={procedure => `${procedure.code} - ${procedure.description}`}
                                                            multiple={false}
                                                            options={procedures}
                                                            placeholder="Escolha um Procedimento"
                                                            onChange={(procedure) => {
                                                                if (procedure.length === 0) {
                                                                    procedureTypeHeadRef.current.clear();
                                                                    return;
                                                                }

                                                                cart.procedures.push({
                                                                    procedure_id: procedure[0].id,
                                                                    procedure_percentage: 0,
                                                                });

                                                                calculate();
                                                                procedureTypeHeadRef.current.clear();
                                                            }}
                                                            ref={procedureTypeHeadRef}
                                                        />
                                                        <span className="font-danger">{error}</span>
                                                    </Col>
                                                }
                                            </FormGroup>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>

                            <Col sm="12" md="12">
                                <Card>
                                    <CardBody>
                                        <h6 className="mb-4">Procedimentos</h6>
                                        {isLoadingTotal ?
                                            <div className="loader-box">
                                                <div className="loader-4"></div>
                                            </div>
                                            :
                                            <div className="section-blur">
                                                {(!appointment.insurance_id || !appointment.doctor_id || !appointment.degree_id) && <div className="section-blur-overlay"></div>}
                                                <FormGroup className="row">
                                                    <Col sm="12">
                                                        <Table className="table-border-horizontal">
                                                            <thead>
                                                                <tr>
                                                                    <th>%</th>
                                                                    <th className="text-left">Nome</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            {cart.procedures?.map((procedure, key) => (
                                                                <tbody>
                                                                    <tr key={procedure.key}>
                                                                        <td>
                                                                            <Input className="form-control" type="select" onChange={(e) => {
                                                                                procedure.procedure_percentage = e.target.value
                                                                                calculate()
                                                                            }}>
                                                                                <option selected={procedure.procedure_percentage === 100 || procedure.percentage === 100} value={100}>100%</option>
                                                                                <option selected={procedure.procedure_percentage === 70 || procedure.percentage === 70} value={70}>70%</option>
                                                                                <option selected={procedure.procedure_percentage === 50 || procedure.percentage === 50} value={50}>50%</option>
                                                                            </Input>
                                                                        </td>
                                                                        <td>
                                                                            <div className="text-left">
                                                                                <div className="f-w-600">{procedure.procedure_code} - {procedure.procedure_description} - {formatCurrency(procedure.original_value)}</div>
                                                                                <small className="d-block">{cart.acomodation_value}x Acomodação, {cart.emergency_percentage}% Emergência, {cart.degree_percentage}% Grau de Participação - {formatCurrency(procedure.value)}</small>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-2 mr-3 text-center">
                                                                                    <i className="fa fa-copy" onClick={() => {
                                                                                        cart.procedures.push(procedure);
                                                                                        calculate();
                                                                                    }} style={{ cursor: "pointer" }}></i>
                                                                                </div>

                                                                                <div className="col-2 mr-3 text-center">
                                                                                    <i className="fa fa-trash-o mr-2" onClick={() => {
                                                                                        cart.procedures = cart.procedures.filter(p => p.index !== procedure.index);
                                                                                        calculate();
                                                                                    }} style={{ cursor: "pointer" }}></i>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ))}
                                                        </Table>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="col-sm-3 col-form-label lbl-align-self-end">{"Total:"}</Label>
                                                    <Col sm="9">
                                                        <div className="text-right">
                                                            <h6 className="f-w-600">{`${formatCurrency(cart.total)}`}</h6>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>


                        <div className="action-float">
                            <div className="dropup-basic ">
                                {isLoadingInputDegree
                                    ?
                                    <div className="loader-box">
                                        <div className="loader-4"></div>
                                    </div>
                                    :
                                    <Dropdown className="dropup">
                                        <Button color="primary" className="dropbtn"><i className="fa fa-plus-square-o"></i></Button>
                                        <DropdownMenu className="dropup-content">
                                            {degrees.map((degree, index) => (
                                                <DropdownItem key={index} onClick={(e) => duplicateAppointment(degree)}>{degree.degree}</DropdownItem>
                                            ))
                                            }
                                        </DropdownMenu>
                                    </Dropdown>
                                }
                            </div>

                            <span onClick={() => save()} className="bg-success"><i className="fa fa-save"></i></span>
                        </div>
                    </>
                }
            </Container>
        </React.Fragment>
    )
}

export default Appointment;