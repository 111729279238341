import React, { useState } from "react";
import { Button, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ImportProcedure from "../../procedure/globalProcedure/importProcedure/importProcedure";

const SelectImport = (props) => {
  const { onCloseModal, openImportProcedure, openImportSize } = props

  const openProcedureImport = () => {
    onCloseModal()
    openImportProcedure()
  }

  const openSizeImport = () => {
    onCloseModal()
    openImportSize()
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Selecione o que deseja Importar'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container>
          <div className="d-flex justify-content-center" >
            <Row>
              <div className="col-6">
                <Button color="primary" onClick={() => openProcedureImport()}>
                  Procedimentos do Convênio
                </Button>
              </div>
              <div className="col-6">
                <Button color="primary" onClick={() => openSizeImport()}>
                  Portes do Convênio
                </Button>
              </div>
            </Row>
          </div>
        </Container>
      </ModalBody>
    </div>
  )
}

export default SelectImport;