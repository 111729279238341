import { Calendar, DollarSign, Home, List, PlusCircle, User, Users } from 'react-feather'

export const MENUITEMS = [
    {
        menutitle: "Cadastros",
        menucontent: "Gerencie seus cadastros",
        Items: [
            { path: `/app/users/userTable`, icon: Users, title: 'Usuários', type: 'link' },
            { path: `/app/patient/patientTable`, icon: Users, title: 'Pacientes', type: 'link' },
            {
                title: 'Médicos', icon: Users, type: 'sub', children: [
                    { path: `/app/doctors/doctorTable`, title: 'Médicos', type: 'link' },
                    { path: `/app/participation_degree`, title: 'Graus de Participação', type: 'link' },
                ]
            },
            { path: `/app/clinics/clinicTable`, icon: Home, title: 'Clínicas', type: 'link' },
            { path: `/app/healthInsurance/healthInsuranceTable`, icon: PlusCircle, title: 'Convênios', type: 'link' },
            { path: `/app/globalProcedures`, title: 'Procedimentos', icon: List, type: 'link' },
            { path: `/app/holidays`, title: 'Feriados', icon: Calendar, type: 'link' },
        ]
    },
    {
        menutitle: "Agendamentos",
        menucontent: "Gerencie os Agendamentos",
        Items: [
            {
                title: 'Agendamentos', icon: Users, type: 'sub', children: [
                    { path: `/app/appointment`, title: 'Novo Agendamento', type: 'link' },
                    { path: `/app/appointmentTable`, title: 'Lista de Agendamentos', type: 'link' },
                ]
            },
        ]
    },
    {
        menutitle: "Faturamentos",
        menucontent: "Gerencie os Faturamentos",
        Items: [
            {
                title: 'Faturamentos', icon: DollarSign, type: 'link', path: `/app/billing`
            },
        ]
    },
]