import React from "react";
import { Col, Container, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../../../../layout/loader";
import axios from "axios";
import { authHeader } from "../../../../services/fack.backend";
import Swal from "sweetalert2";
import Step1 from "../new/step1";
import Step2 from "../new/step2";
import Step3 from "../new/step3";

const EditClinic = (props) => {
  const { onCloseModal, fetchClinics, singleClinic } = props

  const [loading, setLoading] = useState(false)

  const [acomodations, setAcomodations] = useState([])

  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)

  const [form1, setForm1] = useState({
    name: singleClinic.name ? singleClinic.name : '',
    fantasyName: singleClinic.fantasyName ? singleClinic.fantasyName : '',
    socialReason: singleClinic.socialReason ? singleClinic.socialReason : '',
    phone: singleClinic.phone ? singleClinic.phone : '',
    email: singleClinic.email ? singleClinic.email : '',
    stateSubscription: singleClinic.stateSubscription ? singleClinic.stateSubscription : '',
    neighborhoodSubscription: singleClinic.neighborhoodSubscription ? singleClinic.neighborhoodSubscription : '',
    cnpj: singleClinic.cnpj ? singleClinic.cnpj : '',
    initials: singleClinic.initials ? singleClinic.initials : '',
  })
  const [form2, setForm2] = useState({
    cep: singleClinic.cep ? singleClinic.cep : '',
    state: singleClinic.state ? singleClinic.state : '',
    city: singleClinic.city ? singleClinic.city : '',
    neighborhood: singleClinic.neighborhood ? singleClinic.neighborhood : '',
    address: singleClinic.address ? singleClinic.address : '',
    number: singleClinic.number ? singleClinic.number : '',
    complement: singleClinic.complement ? singleClinic.complement : '',
    neighborhoodCode: singleClinic.neighborhoodCode ? singleClinic.neighborhoodCode : ''
  })
  const [form3, setForm3] = useState([])

  const cards = {
    columns: [
      {
        id: 3,
        description: 'Ambulatório'
      },
      {
        id: 4,
        description: 'Apartamento'
      },
      {
        id: 5,
        description: 'Enfermaria'
      },
    ]
  }

  useEffect(() => {
    fetchAcomodations()
  }, [])




  const fetchAcomodations = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clinicAcomodation/getAcomodations/` + singleClinic.id, { headers: authHeader() })
      .then(response => {
        let includedNames = []
        Object.values(response.data).map(acomodation => {
          includedNames.push(acomodation.description)
        })

        let temp = {
          columns: [
            {
              id: 1,
              title: 'Não Inclusas',
              cards: cards.columns.filter(card => !includedNames.includes(card.description))
            },
            {
              id: 2,
              title: 'Inclusas',
              cards: response.data
            },
          ]
        }

        setAcomodations(temp)
      })
  }

  const setInfo1 = data => {
    let temp = form1
    for (let [key, value] of Object.entries(temp)) {
      for (const [index, v] of Object.entries(data)) {
        if (key === index) {
          temp[key] = v
        }
      }
    }
    setForm1(temp)
    setStep1(false)
    setStep2(true)
  }

  const setInfo2 = data => {
    let temp = form2
    for (let [key, value] of Object.entries(temp)) {
      for (const [index, v] of Object.entries(data)) {
        if (key === index) {
          temp[key] = v
        }
      }
    }
    setForm2(temp)
    setStep2(false)
    setStep3(true)
  }

  const setInfo3 = async (data) => {
    setIsLoading()
    setStep3(false)

    const form = {
      1: form1,
      2: form2,
      3: data
    }

    await axios.put(`${process.env.REACT_APP_API_URL}/api/clinic/edit/` + singleClinic.id, form, { headers: authHeader() })
      .then((response) => {
        onCloseModal()
        fetchClinics()
        Swal.fire('Clínica editada com sucesso!', '', 'success')
      }).catch((error) => {
        onCloseModal()
        Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
      })
  }

  const previousStep = () => {
    if (step2) {
      setStep1(true)
      setStep2(false)
    }
    if (step3) {
      setStep2(true)
      setStep3(false)
    }
  }

  const setIsLoading = () => {
    setLoading(!loading)
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            {loading &&
              <Col>
                <div className="loader-box">
                  <div className="loader-7"></div>
                </div>
              </Col>
            }
            {step1 &&
              <Step1
                formInfo={form1}
                setInfo={(data) => setInfo1(data)}
              />
            }
            {step2 &&
              <Step2
                formInfo={form2}
                setInfo={(data) => setInfo2(data)}
                previousStep={previousStep}
              />
            }
            {step3 &&
              <Step3
                formInfo={form3}
                setInfo={(data) => setInfo3(data)}
                acomodations={acomodations}
                previousStep={previousStep}
                isEdit={true}
              />
            }
          </Row>
        </Container>
      </ModalBody>
    </div>
  )
}

export default EditClinic