import axios from "axios";
import React from "react"
import { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../services/fack.backend";



const EditDoctor = (props) => {
  const { onCloseModal, singleDoctor, getDoctors } = props

  const [isLoadingDoctor, setLoadingDoctor] = useState(false)
  const [isLoadingInput, setIsLoadingInput] = useState(true)
  const [isLoadingDoctorClinic, setIsLoadingDoctorClinic] = useState(true)
  const [isDeletingSpecialty, setDeletingSpecialty] = useState(false)
  const [isDeletingClinic, setDeletingClinic] = useState(false)
  const [isEditing, setEditing] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [crm, setCrm] = useState('')
  const [specialties, setSpecialties] = useState({})
  const [specialty, setSpecialty] = useState('')
  const [phone, setPhone] = useState('')
  const [observation, setObservation] = useState('')
  const [clinicsToCreate, setClinicsToCreate] = useState([])
  const [clinicToCreate, setClinicToCreate] = useState({})
  const [doctorClinics, setDoctorClinics] = useState({})
  const [clinics, setClinics] = useState({})

  const [errors, setErrors] = useState({
    name: '',
    crm: '',
  })
  const [errorSpecialty, setErrorSpecialty] = useState('')
  const [errorClinic, setErrorClinic] = useState('')


  useEffect(() => {
    fetchDoctorSpecialties()
    fetchDoctorClinics();
    fetchClinics();

  }, [])

  const fetchClinics = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clinic/getClinics`, { headers: authHeader() })
      .then((response) => {
        setClinics(response.data)
        setIsLoadingInput(false)
      })
  }

  const fetchDoctorClinics = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/doctor-clinic/${singleDoctor.id}`, { headers: authHeader() })
      .then((response) => {
        setDoctorClinics(response.data)
        setIsLoadingDoctorClinic(false)
      })
  }

  const fetchDoctorSpecialties = async () => {
    setLoadingDoctor(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/doctor-specialty/${singleDoctor.id}`, { headers: authHeader() }).then((response) => {
      setSpecialties(response.data)
      setLoadingDoctor(false)
    })
  }

  const editDoctor = async (id) => {
    setEditing(true)
    const bodyData = {
      name: name === "" ? singleDoctor.name : name,
      email: email === "" ? singleDoctor.email : email,
      crm: crm === "" ? singleDoctor.crm : crm,
      phone: phone === "" ? singleDoctor.phone : phone,
      observation: observation === "" ? singleDoctor.observation : observation,
      specialties: specialties,
      clinics: doctorClinics
    }

    axios.put(`${process.env.REACT_APP_API_URL}/api/doctor/edit/` + id, bodyData, { headers: authHeader() }).then(response => {
      setEditing(false)
      onCloseModal()
      getDoctors()
      Swal.fire('Médico editado com sucesso!', '', 'success')
    }).catch(err => {
      setEditing(false)
      Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
    })
  }

  const deleteSpecialty = async (value) => {
    setDeletingSpecialty(true)
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/doctor-specialty/${value.id}`, { headers: authHeader() }).then((res) => {
      axios.get(`${process.env.REACT_APP_API_URL}/api/doctor-specialty/${singleDoctor.id}`, { headers: authHeader() }).then((response) => {
        setSpecialties({})
        setDeletingSpecialty(false)
      })
    })
  }

  const addToSpecialtiesList = () => {
    if (specialty === "") {
      setErrorSpecialty('Informe o nome da especialidade')
      return
    } else {
      setErrorSpecialty('')
      setSpecialties({ id: null, name: specialty })
      setSpecialty('')
    }
  }

  const addToClinicsToCreateList = () => {
    setErrorClinic('')
    const temp = doctorClinics
    let dontPush = false;
    console.log(clinicToCreate)
    temp.map(v => {
      if (!v.clinic) {
        if (clinicToCreate == v.id) {
          setErrorClinic('Clínica já adicionada')
          dontPush = true;
          return
        }
      } else {
        if (clinicToCreate == v.clinic_id) {
          setErrorClinic('Clínica já adicionada')
          dontPush = true;
          return
        }
      }
    })

    if (!dontPush) {
      clinics.map(v => {
        if (v.id == clinicToCreate) {
          temp.push(v)
        }
      })
      setDoctorClinics(temp)
      setClinicToCreate('')
    }
  }

  const deleteClinic = async (value, key) => {
    setErrorClinic('')
    const temp = doctorClinics
    if (!value.clinic) {
      setDoctorClinics(temp.filter(v => v.id !== value.id))
      return
    } else {
      setDeletingClinic(true)
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/doctor-clinic/${value.id}`, { headers: authHeader() }).then((res) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/doctor-clinic/${singleDoctor.id}`, { headers: authHeader() }).then((response) => {
          setDoctorClinics(temp.filter(v => v.id !== value.id))
          setDeletingClinic(false)
        })
      })
    }

  }


  return (
    <div>

      <ModalHeader toggle={onCloseModal}>
        {'Editar Médico'}
      </ModalHeader>
      {isLoadingDoctor ?
        <div className="loader-box">
          <div className="loader-7"></div>
        </div>
        :
        <div>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label className="col-form-label">{"Nome:"}</Label>
                <Input className="form-control" type="text" onChange={(e) => setName(e.target.value)} defaultValue={singleDoctor.name} />
                <span className="font-danger">{errors.name}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"CRM:"}</Label>
                <Input type="text" className="form-control" onChange={(e) => setCrm(e.target.value)} defaultValue={singleDoctor.crm}></Input>
                <span className="font-danger">{errors.crm}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Email:"}</Label>
                <Input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} defaultValue={singleDoctor.email}></Input>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Telefone:"}</Label>
                <Input type="tel" className="form-control" onChange={(e) => setPhone(e.target.value)} defaultValue={singleDoctor.phone}></Input>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Clínicas:"}</Label>
                <div className="input-group">
                  {isLoadingInput || isDeletingClinic
                    ?
                    <Col>
                      <div className="loader-box">
                        <div className="loader-4"></div>
                      </div>
                    </Col>
                    :
                    <div className="input-group">
                      <Input className="form-control" type="select" onChange={(e) => setClinicToCreate(e.target.value)}>
                        <option disabled selected value></option>
                        {clinics.map(clinic => (
                          <option value={clinic.id}>{clinic.name}</option>
                        ))}
                      </Input>
                      <Button onClick={() => addToClinicsToCreateList()}><i className="fa fa-plus"></i></Button>
                    </div>
                  }
                  <div>
                    <span className="font-danger">{errorClinic}</span>
                  </div>
                </div>
                {isLoadingDoctorClinic || isDeletingClinic
                  ?
                  <></>
                  :
                  <Table className="table-border-horizontal">
                    <thead>
                      <tr>
                        <th scope="col-10">{""}</th>
                        <th scope="col-2">{""}</th>
                      </tr>
                    </thead>
                    {doctorClinics.map((value, key) => (
                      <tbody>
                        <tr key={key}>
                          <td>{value.name === undefined ? value.clinic.name : value.name}</td>
                          <td>
                            <i className="fa fa-trash-o" onClick={() => deleteClinic(value)} style={{ cursor: "pointer" }}></i>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                }
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Especialidade:"}</Label>
                <div className="input-group">
                  <Input id="specialty" type="text" className="form-control" value={specialty} onChange={(e) => setSpecialty(e.target.value)}></Input>
                  {specialties.name != undefined ? <></> : <Button className="loader-7" onClick={() => addToSpecialtiesList()}><i className="fa fa-plus"></i></Button>}
                </div>
                <div>
                  <span className="font-danger">{errorSpecialty}</span>
                </div>
              </FormGroup>
              {isDeletingSpecialty ?
                <div className="loader-box">
                  <div className="loader-7"></div>
                </div>
                :
                <div className="table-responsive">
                  <Table className="table-border-horizontal">
                    <thead>
                      <tr>
                        <th scope="col">{""}</th>
                        <th scope="col">{""}</th>
                      </tr>
                    </thead>
                    {specialties.name != undefined ?
                      <tbody>
                        <tr>
                          <td>{specialties.name}</td>
                          <td>
                            <i className="fa fa-trash-o" onClick={() => deleteSpecialty(specialties)} style={{ cursor: "pointer" }}></i>
                          </td>
                        </tr>
                      </tbody>
                      :
                      <></>
                    }
                  </Table>
                </div>
              }
              <FormGroup>
                <Label className="col-form-label">{"Observação:"}</Label>
                <Input type="textarea" className="form-control" rows="3" onChange={(e) => setObservation(e.target.value)} defaultValue={singleDoctor.observation}></Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={onCloseModal}>Fechar</Button>
            <Button color="success" onClick={() => editDoctor(singleDoctor.id)}>{isEditing ? "Editando..." : "Salvar"}</Button>
          </ModalFooter>
        </div>
      }
    </div>
  )
}


export default EditDoctor;