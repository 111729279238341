import axios from "axios";
import React, { useEffect, useState } from "react";
import { Loader } from "react-feather";
import { Breadcrumb, Button, Card, CardHeader, Col, Container, Input, Modal, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../services/fack.backend";
import ImportProcedure from "../procedure/globalProcedure/importProcedure/importProcedure";
import ImportSize from "../sizes/import/importSize";
import CreateHealthInsurance from "./form/create/createHealthInsurance";
import EditHealthInsurance from "./form/edit/editHealthInsurance";
import SelectImport from "./import/selectImport";
import ViewHealthInsurance from "./view/viewHealthInsurance";


const HealthInsuranceTable = (props) => {
  const [healthInsurances, setHealthInsurances] = useState([])
  const [singleInsurance, setSingleInsurance] = useState({})
  const [searchKeyword, setSearchKeyword] = useState('')
  const [filteredHealthInsurances, setFilteredHealthInsurances] = useState([])

  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [isOpenView, setIsOpenView] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenImportModal, setIsOpenImportModal] = useState(false)
  const [isOpenImportProcedure, setIsOpenImportProcedure] = useState(false)
  const [isOpenImportSize, setIsOpenImportSize] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const cards = {
    columns: [
      {
        id: 3,
        description: 'Ambulatório'
      },
      {
        id: 4,
        description: 'Apartamento'
      },
      {
        id: 5,
        description: 'Enfermaria'
      },
    ]
  }

  useEffect(() => {
    fetchHealthInsurances()
  }, [])

  const fetchHealthInsurances = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/healthInsurance/getAll`, { headers: authHeader() })
      .then(response => {
        setHealthInsurances(response.data)
        setFilteredHealthInsurances(response.data)
        setIsLoading(false)
      }).catch(err => {
        console.log(err)
      })
  }



  //Create Modal
  const onOpenCreateModal = () => {
    setIsOpenCreate(true)
  };
  const onCloseCreateModal = () => {
    setIsOpenCreate(false)
  };

  //View Modal
  const onOpenViewModal = (value) => {
    setSingleInsurance(value)
    setIsOpenView(true)
  };
  const onCloseViewModal = () => {
    setIsOpenView(false)
  };

  //Edit Modal
  const onOpenEditModal = (value) => {
    setSingleInsurance(value)
    setIsOpenEdit(true)
  };
  const onCloseEditModal = () => {
    setIsOpenEdit(false)
  };

  const openImportModal = () => {
    setIsOpenImportModal(true)
  }

  const closeImportModal = () => {
    setIsOpenImportModal(false)
  }

  const openImportProcedure = () => {
    setIsOpenImportProcedure(true)
  }

  const closeImportProcedure = () => {
    setIsOpenImportProcedure(false)
  }

  const openImportSize = () => {
    setIsOpenImportSize(true)
  }

  const closeImportSize = () => {
    setIsOpenImportSize(false)
  }

  const detailsPage = (value) => {
    window.location.href = `${process.env.REACT_APP_URL}/app/healthInsurance/healthInsuranceTable/details/${value.id}/Dubai`
  }

  const exportHealthInsurances = async () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/healthInsurance/export`
  }

  const deleteHealthInsurance = async (healthInsurance) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o Convênio ${healthInsurance.name}`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/healthInsurance/delete/` + healthInsurance.id, { headers: authHeader() }).then(response => {
          Swal.fire('Convênio deletado com sucesso!', '', 'success')
          fetchHealthInsurances()
        }).catch(error => {
          Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
        })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  const handleSearch = (e) => {

    const temp = healthInsurances.filter((item) => {
      return item.name.toLowerCase().indexOf(e.toLowerCase()) !== -1;
    });
    setFilteredHealthInsurances(temp)
  }

  const acomodations = {
    columns: [
      {
        id: 1,
        title: 'Não Inclusas',
        cards: [
          {
            id: 3,
            description: 'Ambulatório'
          },
          {
            id: 4,
            description: 'Apartamento'
          },
          {
            id: 5,
            description: 'Enfermaria'
          },
        ]
      },
      {
        id: 2,
        title: 'Inclusas',
        cards: []
      },
    ]
  }

  return (
    <React.Fragment>
      <Breadcrumb parent="Convênios" title="Lista de Convênios" />
      <Container fluid={true}>
        <Modal isOpen={isOpenImportModal}>
          <SelectImport
            onCloseModal={closeImportModal}
            openImportProcedure={openImportProcedure}
            openImportSize={openImportSize}
          />
        </Modal>
        <Modal isOpen={isOpenImportProcedure}>
          <ImportProcedure
            onCloseModal={closeImportProcedure}
            isGlobal={false}
          />
        </Modal>
        <Modal isOpen={isOpenImportSize}>
          <ImportSize
            onCloseModal={closeImportSize}
          />
        </Modal>
        <Modal isOpen={isOpenCreate} size="lg">
          <CreateHealthInsurance
            onCloseModal={onCloseCreateModal}
            acomodations={acomodations}
            fetchHealthInsurances={fetchHealthInsurances}
          />
        </Modal>

        <Modal isOpen={isOpenView} size="lg">
          <ViewHealthInsurance
            onCloseModal={onCloseViewModal}
            singleInsurance={singleInsurance}
            fetchHealthInsurances={fetchHealthInsurances}
          />
        </Modal>

        <Modal isOpen={isOpenEdit} size="lg">
          <EditHealthInsurance
            onCloseModal={onCloseEditModal}
            singleInsurance={singleInsurance}
            fetchHealthInsurances={fetchHealthInsurances}
            acomodations={cards}
          />
        </Modal>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{"Convênios"}</h5>
                    <span> {"Veja todos os convênios, crie, edite ou delete-os."}</span>
                    <div className="pull-right">
                      <Button className="mr-5" color='primary' onClick={() => onOpenCreateModal()}><i className="fa fa-plus"></i> Convênio</Button>
                      <Button className="mr-5" color='success' onClick={() => exportHealthInsurances()}><i className="fa fa-plus"></i> Exportar</Button>
                      <Button color='secondary' onClick={() => openImportModal()}><i className="fa fa-plus"></i> Importar</Button>
                      <div className="search">
                        <div className="mb-3">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Pesquisar"
                            defaultValue={searchKeyword}
                            onChange={(e) => handleSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  {isLoading
                    ?
                    <div className="loader-box">
                      <div className="loader-7"></div>
                    </div>
                    :
                    <div className="table-responsive">
                      <Table className="table-border-horizontal">
                        <thead>
                          <tr>
                            <th scope="col">{"Nome"}</th>
                            <th scope="col">{"Tipo"}</th>
                            <th scope="col">{""}</th>
                          </tr>
                        </thead>
                        {filteredHealthInsurances.map((value, key) => (
                          <tbody>
                            <tr key={key}>
                              <td key={key}>{value.name}</td>
                              <td key={key}>{value.type}</td>
                              <td className="table-action-button">
                                <i id="info-button" className="fa fa-align-justify" style={{ cursor: "pointer" }} onClick={() => detailsPage(value)}></i>
                                <i id="look-button" className="fa fa-eye" onClick={() => { onOpenViewModal(value) }} style={{ cursor: "pointer" }}></i>
                                <i id={`edit-button`} className="fa fa-pencil" onClick={() => { onOpenEditModal(value) }} style={{ cursor: "pointer" }}></i>
                                <i id={`delete-button`} className="fa fa-trash-o" onClick={() => deleteHealthInsurance(value)} style={{ cursor: "pointer" }}></i>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  }
                </Card>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}


export default HealthInsuranceTable;