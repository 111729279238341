import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, ListGroup, ListGroupItem, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { authHeader } from "../../../services/fack.backend";

const ViewHealthInsurance = (props) => {
  const { onCloseModal, singleInsurance } = props
  const [loading, setLoading] = useState(true)
  const [acomodations, setAcomodations] = useState([])

  const fetchAcomodations = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/healthInsuranceAcomodation/getAcomodations/` + singleInsurance.id, { headers: authHeader() })
      .then(response => {
        setAcomodations(response.data)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchAcomodations()
  }, [])

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        {"Visualizar Convênio"}
      </ModalHeader>
      <ModalBody>
        {loading ?
          <Col>
            <div className="loader-box">
              <div className="loader-7"></div>
            </div>
          </Col>
          :
          <Container>
            <Row>
              <Col>
                <Label className="col-form-label">{"Nome:"}</Label>
                <Input className="form-control" type="text" disabled={true} defaultValue={singleInsurance.name} />
              </Col>
              <Col>
                <Label className="col-form-label">{"Tipo:"}</Label>
                <Input className="form-control" type="text" disabled={true} defaultValue={singleInsurance.type} />
              </Col>
            </Row>
            <br />
            <Row>
              <Label className="form-label">{'Acomodações:'}</Label>
              <ListGroup>
                {acomodations.map(value => (
                  <ListGroupItem>{value.name}</ListGroupItem>
                ))}
              </ListGroup>
            </Row>
          </Container>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="danger" className="float-right" onClick={() => onCloseModal()}>Fechar</Button>
      </ModalFooter>
    </div>
  )
}

export default ViewHealthInsurance;