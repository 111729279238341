import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { authHeader, generateUUID } from "../../../services/fack.backend";
import Board, { moveCard } from '@asseinfo/react-kanban';

const LinkHealthInsurance = (props) => {
  const { onCloseModal, acomodations, getLinkedAcomodations, healthInsurances, singleInsurance, isEdit, onCloseEditModal, currentKey } = props

  useEffect(() => {
  }, [])

  const [enrollment, setEnrollment] = useState('')
  const [errors, setErrors] = useState({})
  const [selectedInsuranceId, setSelectedInsuranceId] = useState(isEdit ? singleInsurance.health_insurance.id : '')
  const [isLoading, setIsLoading] = useState(true)
  const [controlledBoard, setBoard] = useState(acomodations);

  const linkHealthInsurance = async () => {
    if (isEdit) {
      let tempEnrollment = singleInsurance.patient_insurance.enrollment

      if (selectedInsuranceId === "") {
        setErrors({
          selectedInsuranceId: "É necessário escolher um Convênio"
        })
      } else {

        const temp = healthInsurances.filter(insurance => insurance.id == selectedInsuranceId)

        const data = {
          enrollment: enrollment !== '' ? enrollment : tempEnrollment,
          selectedInsurance: temp,
          acomodations: controlledBoard.columns[1].cards,
          key: singleInsurance.key
        }

        getLinkedAcomodations(data)
        onCloseEditModal()
      }
    } else {
      if (selectedInsuranceId === "") {
        setErrors({
          selectedInsuranceId: "É necessário escolher um Convênio"
        })
      } else {
        const temp = healthInsurances.filter(insurance => insurance.id == selectedInsuranceId)

        const data = {
          enrollment: enrollment,
          selectedInsurance: temp,
          acomodations: controlledBoard.columns[1].cards,
          key: generateUUID()
        }
        getLinkedAcomodations(data)
        onCloseModal()
      }
    }
  }

  function ControlledBoard() {

    function handleCardMove(_card, source, destination) {
      const updatedBoard = moveCard(controlledBoard, source, destination);
      setBoard(updatedBoard);
    }

    return (
      <Board onCardDragEnd={handleCardMove} disableColumnDrag>
        {controlledBoard}
      </Board>
    );
  }

  return (
    <div>
      <ModalHeader toggle={isEdit ? onCloseEditModal : onCloseModal}>
        <h5>{'Vincular Convênio'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Form>
            <Row>
              <Col>
                <Label className="col-form-label">{"Matricula:"}</Label>
                <Input className="form-control" type="text" onChange={(e) => setEnrollment(e.target.value)} defaultValue={isEdit ? singleInsurance.patient_insurance.enrollment : enrollment} />
                <span className="font-danger">{errors.enrollment}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label">{"Convênio:"}</Label>
                <Input type="select" name="select" className="form-control" onChange={(e) => setSelectedInsuranceId(e.target.value)}>
                  {!isEdit && <option selected disabled />}
                  {healthInsurances.map(insurance => (
                    isEdit && singleInsurance.health_insurance.id == insurance.id ?
                      <option value={insurance.id} selected>{insurance.name}</option>
                      :
                      <option value={insurance.id}>{insurance.name}</option>
                  ))}
                </Input>
                <span className="font-danger">{errors.selectedInsuranceId}</span>
              </Col>
            </Row>
            <br />
            <Row className="text-center">
              <Col>
                <ControlledBoard />
              </Col>
            </Row>
          </Form>
          <br />
          <ModalFooter>
            <Button color="primary" className="float-right" onClick={() => linkHealthInsurance()}>{'Vincular'}</Button>
          </ModalFooter>
        </Container>
      </ModalBody>
    </div>
  )
}

export default LinkHealthInsurance;