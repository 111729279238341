import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { authHeader } from "../../../../services/fack.backend";
import CreateEmergencyDay from "./form/create/createEmergencyDay";
import EditEmergencyDay from "./form/edit/editEmergencyDay";
import Swal from "sweetalert2";
import moment from 'moment';


const EmergencyDays = (props) => {
  const { insurance_id } = props
  const [isLoading, setIsLoading] = useState(false)
  const [emergencyDays, setEmergencyDays] = useState([])
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [singleDay, setSingleDay] = useState({})

  useEffect(() => {
    moment.locale('pt-br')
    fetchDays();
  }, [])

  const fetchDays = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/emergencyDays/${insurance_id}`, { headers: authHeader() })
      .then(res => {
        setEmergencyDays(res.data)
        setIsLoading(false)
      })
  }

  const onOpenCreate = () => {
    setIsOpenCreate(true)
  }

  const onCloseCreate = () => {
    setIsOpenCreate(false)
  }

  const onOpenEdit = (day) => {
    setSingleDay(day)
    setIsOpenEdit(true)
  }

  const onCloseEdit = () => {
    setIsOpenEdit(false)
  }

  const deleteDay = async (day) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar ${day.day.description} dos Dias de Emergência para este convênio?`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/emergencyDays/${day.id}`, { headers: authHeader() })
          .then(response => {
            Swal.fire(`${day.day.description} deletado com sucesso dos Dias de Emergência!`, '', 'success')
            fetchDays()
          }).catch(error => {
            Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
          })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Button className="pull-right" color='primary' onClick={() => onOpenCreate()}><i className="fa fa-plus"></i></Button>
        <Modal isOpen={isOpenCreate}>
          <CreateEmergencyDay
            onCloseModal={onCloseCreate}
            fetchDays={fetchDays}
            insurance_id={insurance_id}
          />
        </Modal>
        <Modal isOpen={isOpenEdit}>
          <EditEmergencyDay
            onCloseModal={onCloseEdit}
            fetchDays={fetchDays}
            insurance_id={insurance_id}
            singleDay={singleDay}
            deleteDay={(data) => deleteDay(data)}
          />
        </Modal>
        {isLoading ?
          <div className="loader-box">
            <div className="loader-7"></div>
          </div>
          :
          <CardBody>
            <FormGroup className="row">
              {emergencyDays.map((day) => (
                <Col className="col-4">
                  <Card style={{ cursor: "pointer" }} onClick={() => onOpenEdit(day)}>
                    <CardBody>
                      <Row>
                        <h6 className="text-center">{`${day.day.description}`}</h6>
                        <Col>
                          <Label className="col-form-label">{"Porcentagem:"}</Label>
                          <Input disabled defaultValue={`${day.percentage}%`} />
                        </Col>
                        <Col>
                          <Label className="col-form-label">{"Horário Inicial:"}</Label>
                          <Input disabled defaultValue={day.initial} type="time" />
                        </Col>
                        <Col>
                          <Label className="col-form-label">{"Horário Final:"}</Label>
                          <Input disabled defaultValue={day.final} type="time" />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </FormGroup>
          </CardBody>
        }
      </Container>
    </React.Fragment >
  )
}

export default EmergencyDays;