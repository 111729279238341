import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../../../../services/fack.backend";

const CreateInsuranceProcedure = (props) => {
  const { onCloseModal, fetchProcedures, insurance_id } = props
  const [isLoadingInputSize, setIsLoadingInputSize] = useState(true)
  const [isLoadingInputHealthInsurance, setIsLoadingInputHealthInsurance] = useState(true)
  const [isLoadingInputProcedures, setIsLoadingInputProcedures] = useState(true)

  const [errors, setErrors] = useState({})
  const [code, setCode] = useState('')
  const [description, setDescription] = useState('')
  const [value, setValue] = useState(undefined)
  const [size, setSize] = useState('')
  const [health_insurance, setHealthInsurance] = useState('')
  const [sizes, setSizes] = useState({});
  const [healthInsurances, setHealthInsurances] = useState({});
  const [procedures, setProcedures] = useState({});
  const [procedure, setProcedure] = useState({});


  useEffect(() => {
    fetchSizes();
    fetchHealthInsurances();
    fetchProceduresGlobals();
  }, [])

  const fetchSizes = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/sizes`, { headers: authHeader() })
      .then(res => {
        setSizes(res.data)
        setIsLoadingInputSize(false)
      })
  }

  const fetchHealthInsurances = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/healthInsurance/getAll`, { headers: authHeader() })
      .then(res => {
        setHealthInsurances(res.data)
        setIsLoadingInputHealthInsurance(false)
      })
  }

  const fetchProceduresGlobals = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/globalProcedure`, { headers: authHeader() })
      .then(res => {
        setProcedures(res.data)
        setIsLoadingInputProcedures(false)
      })
  }

  const createProcedure = async () => {
    const formData = {
      procedure_id: procedure,
      size_id: size,
      health_insurance_id: insurance_id,
      value: value
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/api/insuranceProcedure`, formData, { headers: authHeader() })
      .then(res => {
        onCloseModal()
        fetchProcedures()
        Swal.fire('Procedimento de Convênio criado com sucesso!', '', 'success')
      }).catch(err => {
        onCloseModal()
        Swal.fire("Ocorreu um erro, tente novamente!", '', 'error');
      })
  }

  const onAmountChange = e => {
    let value = e.target.value
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1.$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ",");

    setValue(value)

    e.target.value = value
    return e
  };

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Cadastrar Procedimento de Convênio'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Form>

            <Label className="col-form-label">{"Porte:"}</Label>
            {isLoadingInputSize
              ?
              <Col>
                <div className="loader-box">
                  <div className="loader-4"></div>
                </div>
              </Col>
              :
              <div>
                <Input className="form-control" type="select" onChange={(e) => setSize(e.target.value)}>
                  <option disabled selected value></option>
                  {sizes.map(size => (
                    <option key={size.id} value={size.id}>{size.description}</option>
                  ))}
                </Input>
              </div>
            }
            <Label className="col-form-label">{"Procedimentos:"}</Label>
            {isLoadingInputProcedures
              ?
              <Col>
                <div className="loader-box">
                  <div className="loader-4"></div>
                </div>
              </Col>
              :
              <div>
                <Input className="form-control" type="select" onChange={(e) => setProcedure(e.target.value)}>
                  <option disabled selected value></option>
                  {procedures.map(procedure => (
                    <option key={procedure.id} value={procedure.id}>{procedure.code} - {procedure.description}</option>
                  ))}
                </Input>
              </div>
            }
            <Label className="col-form-label">{"Valor:"}</Label>
            <Input className="form-control" type="text" value={value} onChange={(e) => onAmountChange(e)} />
            <span className="font-danger">{errors.type}</span>
          </Form>
          <ModalFooter>
            <Button color="primary" className="float-right" onClick={() => createProcedure()}>{'Criar'}</Button>
          </ModalFooter>
        </Container>
      </ModalBody>
    </div>
  )

}

export default CreateInsuranceProcedure;