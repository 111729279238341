import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, ModalFooter, Row } from 'reactstrap';
import axios from 'axios';

const Step2 = (props) => {
  const { formInfo, setInfo, previousStep } = props

  const [loadingInputs, setLoadingInputs] = useState(false)

  const [cep, setCep] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [address, setAddress] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [neighborhoodCode, setNeighborhoodCode] = useState('')

  const [errors, setErrors] = useState({})

  useEffect(() => {
  }, [])

  const nextStep = data => {
    if (state === '' && formInfo.state === '') {
      setErrors({
        state: 'Estado é obrigatório!'
      })
      return
    }
    if (city === '' && formInfo.city === '') {
      setErrors({
        city: 'Cidade é obrigatória!'
      })
      return
    }

    setErrors({})
    const temp = {
      cep: cep !== '' ? cep : formInfo.cep,
      state: state !== '' ? state : formInfo.state,
      city: city !== '' ? city : formInfo.city,
      neighborhood: neighborhood !== '' ? neighborhood : formInfo.neighborhood,
      address: address !== '' ? address : formInfo.address,
      number: number !== '' ? number : formInfo.number,
      complement: complement !== '' ? complement : formInfo.complement,
      neighborhoodCode: neighborhoodCode !== '' ? neighborhoodCode : formInfo.neighborhoodCode,
    }
    setInfo(temp)
  }

  const getCep = async (data) => {
    setLoadingInputs(true)
    await axios.get(`https://viacep.com.br/ws/${data}/json/`).
      then(response => {
        if(response.data.erro){
          setErrors({cep: "CEP inválido, tente novamente!"})
          setLoadingInputs(false)
          
        } else {
          setErrors({})
          setNeighborhood(response.data.bairro)
          setCity(response.data.localidade)
          setAddress(response.data.logradouro)
          setState(response.data.uf)
          setLoadingInputs(false)
        }

      }).catch(error => {
        console.log(error.response)
      })
  }


  return (
    <Fragment>
      <Container fluid={true}>
        <Form>
          <Card className="card-absolute">
            <CardHeader className="bg-primary">
              <h5>{'Passo 2'}</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Label className="col-form-label">{"CEP:"}</Label>
                  <Input className="form-control"
                    type="text"
                    maxLength="8"
                    onChange={(e) => {
                      setCep(e.target.value)
                      if (e.target.value.length === 8) {
                        getCep(e.target.value)
                      }
                    }}
                    defaultValue={cep ? cep : formInfo.cep}
                  />
                  <span className="font-danger">{errors.cep}</span>
                </Col>
                <Col>
                  <Label className="col-form-label">{"Estado:"}</Label>
                  {loadingInputs ?
                    <div className="loader-box">
                      <div className="loader-4"></div>
                    </div>
                    :
                    <div>
                      <Input className="form-control"
                        type="text"
                        onChange={(e) => setState(e.target.value)}
                        defaultValue={state ? state : formInfo.state} />
                      <span className="font-danger">{errors.state}</span>
                    </div>
                  }
                </Col>
                <Col>

                  <Label className="col-form-label">{"Cidade:"}</Label>
                  {loadingInputs ?
                    <div className="loader-box">
                      <div className="loader-4"></div>
                    </div>
                    :
                    <div>
                      <Input className="form-control"
                        type="text"
                        onChange={(e) => setCity(e.target.value)}
                        defaultValue={city ? city : formInfo.city}
                      />
                      <span className="font-danger">{errors.city}</span>
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="col-form-label">{"Bairro:"}</Label>
                  {loadingInputs ?
                    <div className="loader-box">
                      <div className="loader-4"></div>
                    </div>
                    :
                    <Input className="form-control"
                      type="text"
                      onChange={(e) => setNeighborhood(e.target.value)}
                      defaultValue={neighborhood ? neighborhood : formInfo.neighborhood}
                    />
                  }
                </Col>
                <Col>
                  <Label className="col-form-label">{"Endereço:"}</Label>
                  {loadingInputs ?
                    <div className="loader-box">
                      <div className="loader-4"></div>
                    </div>
                    :
                    <Input className="form-control"
                      type="text"
                      onChange={(e) => setAddress(e.target.value)}
                      defaultValue={address ? address : formInfo.address}
                    />
                  }
                </Col>
                <Col>
                  <Label className="col-form-label">{"Número:"}</Label>
                  <Input className="form-control"
                    type="text"
                    onChange={(e) => setNumber(e.target.value)}
                    defaultValue={formInfo.number ? formInfo.number : number}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="col-form-label">{"Complemento:"}</Label>
                  <Input className="form-control"
                    type="text"
                    onChange={(e) => setComplement(e.target.value)}
                    defaultValue={formInfo.complement ? formInfo.complement : complement}
                  />
                </Col>
                <Col>
                  <Label className="col-form-label">{"Cód. Munícipio:"}</Label>
                  <Input className="form-control"
                    type="text"
                    onChange={(e) => setNeighborhoodCode(e.target.value)}
                    defaultValue={formInfo.neighborhoodCode ? formInfo.neighborhoodCode : neighborhoodCode}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
        <ModalFooter>
          <Button color="primary" className="float-left" onClick={() => previousStep()}>Voltar</Button>
          <Button color="primary" className="float-right" onClick={() => nextStep()}>Próximo</Button>
        </ModalFooter>
      </Container>
    </Fragment>
  );
};

export default Step2;