import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { authHeader } from "../../services/fack.backend";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import DataTableComponent from "./dataTableComponent/dataTableComponent";
import ManipulateAppointment from "./manipulateAppointment/manipulateAppointment";
import StaticLoader from "../../layout/static-loader";

const BillingTable = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [appointments, setAppointments] = useState([])
  const [copyAppointments, setCopyAppointments] = useState([])
  const [isToManipulate, setIsToManipulate] = useState(false)
  const [appointmentsToManipulate, setAppointmentsToManipulate] = useState([])
  const [currentAppointment, setCurrentAppointment] = useState([])
  const [hasManipulation, setHasManipulation] = useState(false)
  const [statuses, setStatuses] = useState([])


  useEffect(() => {
    let status = []
    status.push(
      { description: "AGUARDANDO ANALITICA", value: "waiting_analitica", severity: null },
      { description: "PARA ANALISE", value: "analysis", severity: "info" },
      { description: "CONFERIDO PELO SISTEMA", value: "checked_by_system", severity: "success" },
      { description: "ANALISE DE GLOSA", value: "glosa_analysis", severity: "warning" },
      { description: "RECUSA DE GLOSA", value: "glosa_refusal", severity: "danger" },
      { description: "APROVADO", value: "approved", severity: "success" },
      { description: "PAGO POR RECURSO DE GLOSA", value: "paid_for_resource_glosa", severity: "success" })
    setStatuses(status)
    fetchAppointments()
  }, [])

  const fetchAppointments = async () => {
    setAppointmentsToManipulate([])
    setCurrentAppointment([])
    setHasManipulation(false)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/all`, { headers: authHeader() })
      .then(res => {
        const temp = res.data

        temp.map(appointment => {
          var dateArray = appointment.date.split("-");
          var year = dateArray[0];
          var month = parseInt(dateArray[1], 10) - 1;
          var date = dateArray[2];
          var _entryDate = new Date(year, month, date);
          appointment.date = _entryDate
        })
        setAppointments(temp);
        setCopyAppointments(temp)
        setIsLoading(false)
      })
  }

  const removeAppointment = (currentAppointment) => {
    setAppointmentsToManipulate(appointmentsToManipulate.filter(appointment => appointment.id != currentAppointment.id));
    setCurrentAppointment(appointmentsToManipulate[0]);
  }

  const manipulateAppointments = (appointments) => {
    appointments.map(appointment => appointment.has_manipulation = true)
    
    setAppointmentsToManipulate(appointments)
    if (appointments[0].has_manipulation) {
      setHasManipulation(true)
    }

    setCurrentAppointment(appointments[0])
    setIsToManipulate(true)
  }

  const setFilters = async (data) => {
    setIsLoading(true)
    await axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/filter`, data, { headers: authHeader() })
      .then(res => {
        const temp = res.data
        temp.map(appointment => {
          var dateArray = appointment.date.split("-");
          var year = dateArray[0];
          var month = parseInt(dateArray[1], 10) - 1;
          var date = dateArray[2];
          var _entryDate = new Date(year, month, date);
          appointment.date = _entryDate
        })
        setCopyAppointments(temp)
        setIsLoading(false)
      })
  }

  const goBack = () => {
    setIsToManipulate(!isToManipulate)
    const temp = []
    setCurrentAppointment(temp)
    setAppointmentsToManipulate(temp)
    setHasManipulation(false)
  }

  return (
    <React.Fragment>
      {isLoading &&
        <StaticLoader />
      }
      <Container fluid={true}>
        {isToManipulate ?
          <>

            <div className="col">

              <div class="project-list col-md-12">
                <div class="card">
                  <div class="row">
                    {(appointmentsToManipulate.findIndex(current => current.id == currentAppointment.id) + 1) > 1 &&
                      <div className="col-md-3 text-left" onClick={() => setCurrentAppointment(appointmentsToManipulate[appointmentsToManipulate.findIndex(current => current.id == currentAppointment.id) - 1])}>
                        <i className="fa fa-chevron-left"></i>
                      </div>
                    }

                    <div class="col text-center">
                      <span>Exibindo {appointmentsToManipulate.findIndex(current => current.id == currentAppointment.id) + 1} agendamento de {appointmentsToManipulate.length}</span>
                    </div>

                    {appointmentsToManipulate.length > (appointmentsToManipulate.findIndex(current => current.id == currentAppointment.id) + 1) &&
                      <div className="col-md-3 text-right" onClick={() => setCurrentAppointment(appointmentsToManipulate[appointmentsToManipulate.findIndex(current => current.id == currentAppointment.id) + 1])}>
                        <i className="fa fa-chevron-right"></i>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <ManipulateAppointment
              currentAppointment={currentAppointment}
              goBack={() => goBack()}
              hasManipulation={currentAppointment.has_manipulation}
              fetchAppointments={fetchAppointments}
              removeAppointment={(appoiment) => removeAppointment(appoiment)}
            />
          </>
          :
          <DataTableComponent
            appointments={appointments}
            copyAppointments={copyAppointments}
            manipulateAppointments={(data) => manipulateAppointments(data)}
            status={statuses}
            setFilterOnAppointments={(data) => setFilters(data)}
            fetchAppointments={fetchAppointments}
          />
        }
      </Container>

    </React.Fragment>
  )
}

export default BillingTable;