import axios from "axios";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Breadcrumb, Button, Card, CardHeader, Col, Container, Input, Modal, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../services/fack.backend";
import EditClinic from "./form/edit/editClinic";
import CreateClinic from "./form/new/createClinic";
import ViewClinic from "./form/view/viewClinic";

const ClinicTable = (props) => {
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [isOpenView, setIsOpenView] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)

  const [singleClinic, setSingleClinic] = useState({})
  const [clinics, setClinics] = useState([])

  const [searchKeyword, setSearchKeyword] = useState('')
  const [filteredClinics, setFilteredClinics] = useState([])


  //Create Modal
  const onOpenCreateModal = () => {
    setIsOpenCreate(true)
  };
  const onCloseCreateModal = () => {
    setIsOpenCreate(false)
  };

  //View Modal
  const onOpenViewModal = (value) => {
    setSingleClinic(value)
    setIsOpenView(true)
  };
  const onCloseViewModal = () => {
    setIsOpenView(false)
  };

  //Edit Modal
  const onOpenEditModal = (value) => {
    setSingleClinic(value)
    setIsOpenEdit(true)
  };
  const onCloseEditModal = () => {
    setIsOpenEdit(false)
  };

  useEffect(() => {
    fetchClinics()
  }, [])

  const fetchClinics = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clinic/getClinics`, { headers: authHeader() })
      .then((response) => {
        setClinics(response.data)
        setFilteredClinics(response.data)
      })
  }

  const deleteClinic = async (clinic) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o Médico ${clinic.name}`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/clinic/delete/` + clinic.id, { headers: authHeader() }).then(response => {
          Swal.fire('Clínica deletada com sucesso!', '', 'success')
          fetchClinics()
        }).catch(error => {
          Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
        })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  const handleSearch = (e) => {

    const temp = clinics.filter((item) => {
      return item.name.toLowerCase().indexOf(e.toLowerCase()) !== -1;
    });
    setFilteredClinics(temp)
  }

  return (
    <Fragment>
      <Breadcrumb parent="Clínicas" title="Lista de Clínicas" />
      <Container fluid={true}>

      </Container>
      <Modal isOpen={isOpenCreate} size="lg">
        <CreateClinic
          onCloseModal={onCloseCreateModal}
          fetchClinics={fetchClinics}
        />
      </Modal>

      <Modal isOpen={isOpenView} size="lg">
        <ViewClinic
          onCloseModal={onCloseViewModal}
          singleClinic={singleClinic}
        />
      </Modal>

      <Modal isOpen={isOpenEdit} size="lg">
        <EditClinic
          onCloseModal={onCloseEditModal}
          singleClinic={singleClinic}
          fetchClinics={fetchClinics}
        />
      </Modal>

      <Row>
        <Col sm="12">
          <Card>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{"Clínicas"}</h5>
                  <span> {"Veja todos as clínicas, crie, edite ou delete-as."}</span>
                  <Button className="pull-right" color='primary' onClick={() => onOpenCreateModal()}><i className="fa fa-plus"></i> Clínica</Button>
                  <div className="search">
                    <div className="mb-3">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Pesquisar"
                        defaultValue={searchKeyword}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <div className="table-responsive">
                  <Table className="table-border-horizontal">
                    <thead>
                      <tr>
                        <th scope="col">{"Nome"}</th>
                        <th scope="col">{"Email"}</th>
                        <th scope="col">{"Telefone"}</th>
                        <th scope="col">{""}</th>
                        <th scope="col">{""}</th>
                        <th scope="col">{""}</th>
                      </tr>
                    </thead>
                    {filteredClinics.map((value, key) => (
                      <tbody>
                        <tr key={key}>
                          <td>{value.name}</td>
                          <td>{value.email}</td>
                          <td>{value.phone}</td>
                          <td>
                            <i id="look-button" className="fa fa-eye" onClick={() => onOpenViewModal(value)} style={{ cursor: "pointer" }}></i>
                          </td>
                          <td>
                            <i id={`edit-button`} className="fa fa-pencil" onClick={() => onOpenEditModal(value)} style={{ cursor: "pointer" }}></i>
                          </td>
                          <td>
                            <i id={`delete-button`} className="fa fa-trash-o" onClick={() => deleteClinic(value)} style={{ cursor: "pointer" }}></i>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                </div>
              </Card>
            </Col>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}


export default ClinicTable;