import axios from "axios";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { authHeader } from "../../services/fack.backend";

const SizeTable = () => {
  const [sizes, setSizes] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchSizes();
  }, [])

  const fetchSizes = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/sizes`, { headers: authHeader() })
      .then(res => {
        setSizes(res.data)
        setIsLoading(false)
      })
  }


  return (
    <React.Fragment>
      <Breadcrumb parent="Convênios" title="Lista de Convênios" />
      <Container fluid={true}>
        <Row>
          <Card>
            <CardHeader>
              <h5>{"Portes"}</h5>
              <span> {"Valoração dos Portes"}</span>
              <Button className="pull-right" color="success"> Salvar</Button>
            </CardHeader>
            <CardBody>
              {isLoading ?
                <div className="loader-box">
                  <div className="loader-7"></div>
                </div>
                :
                <Row>
                  {sizes.map((size, index) => (
                    <div>
                      <Label className="col-sm-3 col-form-label">{`Porte ${size.description} :`}</Label>
                      <div className="col-4">
                        <Input className="form-control digits" type="number" step="0.01" defaultValue={size.value} />
                      </div>
                    </div>
                  ))}
                </Row>
              }
            </CardBody>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default SizeTable;