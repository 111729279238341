import React, { useEffect, useState } from "react";
import Appointment from "../appointment";
import { useParams } from "react-router";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, FormGroup, Input, Label, Row, Table } from "reactstrap";
import axios from "axios";
import { authHeader } from "../../../services/fack.backend";
import Swal from "sweetalert2";

const ViewAppointment = (props) => {
  const params = useParams();
  const [procedure, setProcedure] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [files, setFiles] = useState([])

  useEffect(() => {
    fetchAppointment()
  }, [])

  const fetchAppointment = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/${params.id}`, { headers: authHeader() })
      .then(res => {
        setProcedure(res.data)
        fetchAppointmentFiles()
      })
  }

  const fetchAppointmentFiles = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/attachments/${params.id}`, { headers: authHeader() })
      .then(res => {
        setFiles(res.data)
        setIsLoading(false)
      })
  }

  const goBack = () => {
    window.location.href = `${process.env.PUBLIC_URL}/app/appointmentTable/Dubai`;
  }

  console.log(procedure)

  return (
    <React.Fragment>
      {isLoading ?
        <div className="loader-box">
          <div className="loader-7"></div>
        </div>
        :
        <Container fluid={true}>
          <div className="row">
            <Col sm="12" md="6">
              <Card>
                <CardHeader>
                  <h5>{"Agendamento"}</h5>
                  <span> {"Registre um Agendamento"}</span>
                </CardHeader>
                <CardBody>
                  <h5>{"Dados do Paciente:"}</h5>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Paciente:"}</Label>
                    <Col sm="9">
                      <div className="input-group">
                        <Input className="form-control" type="text" defaultValue={procedure.patient.name} disabled />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Convênio:"}</Label>
                    <Col sm="9">
                      <Input className="form-control" type="text" defaultValue={procedure.insurance.name} disabled />
                    </Col>
                  </FormGroup>
                  <br />
                  <h5>{"Dados do Agendamento:"}</h5>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Data do Agendamento:"}</Label>
                    <Col sm="9">
                      <Input className="form-control digits" type="date" defaultValue={procedure.date} disabled />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Hora do Agendamento:"}</Label>
                    <Col sm="9">
                      <Input className="form-control digits" type="time" defaultValue={procedure.time} disabled />
                    </Col>
                  </FormGroup>
                  <div className="checkbox checkbox-solid-primary">
                    <Input id="solid3" type="checkbox" checked={procedure.emergency_id !== null ? true : false} disabled />
                    <Label for="solid3">{"Emergência"}</Label>
                  </div>
                  <br />
                  <h5>{"Dados do Contratado:"}</h5>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Clínica:"}</Label>
                    <Col sm="9">
                      <Input className="form-control" type="text" defaultValue={procedure.clinic.name} disabled />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Acomodação:"}</Label>

                    <Col sm="9">
                      <Input className="form-control" type="text" defaultValue={procedure.acomodation !== null ? procedure.acomodation.name : ''} disabled />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Tipo de Agendamento:"}</Label>
                    <Col sm="9">
                      <div className="radio radio-primary">
                        <Input id="radioinline1" type="radio" name="radio1" value="Consulta" checked={procedure.appointmentType === 'Consulta'} disabled />
                        <Label className="mb-0" for="radioinline1">{"Consulta"}</Label>
                      </div>
                      <div className="radio radio-primary">
                        <Input id="radioinline2" type="radio" name="radio1" value="Procedimento" checked={procedure.appointmentType === 'Procedimento'} disabled />
                        <Label className="mb-0" for="radioinline2">{"Procedimento"}</Label>
                      </div>
                      <br />
                      <Input type="text" value={procedure.appointmentTypeDescription} disabled />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>

            <Col sm="12" md="6">
              <Card>
                <CardBody>
                  <h5>{"Dados Gerais:"}</h5>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Observações:"}</Label>
                    <Col sm="9">
                      <textarea className="form-control" value={procedure.observation} rows="5" cols="5" disabled></textarea>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Anexos:"}</Label>
                    <Col>
                      {files.map(file => (
                        <p className="txt-primary"><a href={`${process.env.REACT_APP_API_URL}${file.attachment.path}`} target="_blank">{`${file.attachment.originalName} `}</a></p>
                      ))}
                    </Col>
                  </FormGroup>
                </CardBody>
                <CardBody>
                  <h5>{"Dados do Executor:"}</h5>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"N° Guia:"}</Label>
                    <Col sm="9">
                      <Input className="form-control digits" value={procedure.guideNumber} type="text" disabled />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Médico:"}</Label>
                    <Col sm="9">
                      <Input className="form-control digits" type="text" defaultChecked={procedure.doctor.name} disabled />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Especialidade:"}</Label>
                    <Col sm="9">
                      <Input className="form-control" type="text" defaultValue={procedure.specialty.name} disabled />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label">{"Grau de Participação:"}</Label>
                    <Col sm="9">
                      <Input className="form-control" type="text" defaultValue={procedure.degree.degree} disabled />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Col sm="12">
                      <Table className="table-border-horizontal">
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th></th>
                          </tr>
                        </thead>
                        {procedure.procedures.map((value, key) => (
                          <tbody>
                            <tr key={key}>
                              <td>{value.description}</td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label lbl-align-self-end">{"Total:"}</Label>
                    <Col sm="9">
                      <div className="text-right">
                        <h6 className="f-w-600">{`R$: ${procedure.total}`}</h6>
                        <small className="d-block">{procedure.totalDescription}</small>
                      </div>
                    </Col>
                  </FormGroup>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </div>
        </Container>
      }
    </React.Fragment >
  )
}

export default ViewAppointment;