import React, { useEffect } from "react";
import { Button, Col, Container, Input, Label, ListGroup, ListGroupItem, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const ViewLinkedHealthInsurance = (props) => {
  const { singleInsurance, onCloseModal } = props

  useEffect(() => {
    console.log(singleInsurance)
  }, [])

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        {"Visualizar Convênio"}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <Label className="col-form-label">{"Nome:"}</Label>
              <Input className="form-control" type="text" disabled={true} defaultValue={singleInsurance.health_insurance.name} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="col-form-label">{"Matrícula:"}</Label>
              <Input className="form-control" type="text" disabled={true} defaultValue={singleInsurance.patient_insurance.enrollment} />
            </Col>
          </Row>
          <br />
          <Row>
            <Label className="form-label">{'Acomodações:'}</Label>
            <ListGroup>
              {singleInsurance.acomodations.map(value => (
                <ListGroupItem>{value.description}</ListGroupItem>
              ))}
            </ListGroup>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" className="float-right" onClick={() => onCloseModal()}>Fechar</Button>
      </ModalFooter>
    </div>
  )
}

export default ViewLinkedHealthInsurance;