import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { authHeader } from "../../../../services/fack.backend";
import Board, { moveCard } from '@asseinfo/react-kanban';
import Swal from "sweetalert2";


const EditHealthInsurance = (props) => {
  const { onCloseModal, acomodations, fetchHealthInsurances, singleInsurance } = props;
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [controlledBoard, setBoard] = useState(acomodations)
  const [errors, setErrors] = useState({})


  useEffect(() => {
    fetchAcomodations()
  }, [])

  const editHealthInsurance = async () => {
    const formData = {
      name: name ? name : singleInsurance.name,
      type: type ? type : singleInsurance.type,
      acomodations: controlledBoard.columns
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/api/healthInsurance/edit/${singleInsurance.id}`, formData, { headers: authHeader() })
      .then(response => {
        fetchHealthInsurances()
        onCloseModal()
        Swal.fire('Convênio editado com sucesso!', '', 'success')
      }).catch(err => {
        onCloseModal()
        Swal.fire(`Ocorreu um erro, tente novamente!`, ``, 'error')
      })
  }


  const fetchAcomodations = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/healthInsuranceAcomodation/getAcomodations/` + singleInsurance.id, { headers: authHeader() })
      .then(response => {
        let includedNames = []
        Object.values(response.data).map(acomodation => {
          includedNames.push(acomodation.description)
        })

        let temp = {
          columns: [
            {
              id: 1,
              title: 'Não Inclusas',
              cards: controlledBoard.columns.filter(card => !includedNames.includes(card.description))
            },
            {
              id: 2,
              title: 'Inclusas',
              cards: response.data
            },
          ]
        }

        setBoard(temp)
        setLoading(false)
      })
  }

  function ControlledBoard() {

    function handleCardMove(_card, source, destination) {
      const updatedBoard = moveCard(controlledBoard, source, destination);
      setBoard(updatedBoard);
    }

    return (
      <Board onCardDragEnd={handleCardMove} disableColumnDrag>
        {controlledBoard}
      </Board>
    );
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Editar Convênio'}</h5>

      </ModalHeader>
      <ModalBody>
        {loading ?
          <Col>
            <div className="loader-box">
              <div className="loader-7"></div>
            </div>
          </Col>
          :
          <Container fluid={true}>
            <Form>
              <Label className="col-form-label">{"Nome:"}</Label>
              <Input className="form-control" type="text" onChange={(e) => setName(e.target.value)} defaultValue={singleInsurance.name} />
              <span className="font-danger">{errors.name}</span>
              <Label className="col-form-label">{"Tipo:"}</Label>
              <Input className="form-control" type="text" onChange={(e) => setType(e.target.value)} defaultValue={singleInsurance.type}/>
              <span className="font-danger">{errors.type}</span>
              <ControlledBoard />
            </Form>
            <ModalFooter>
              <Button color="primary" className="float-right" onClick={() => editHealthInsurance()} >{'Editar'}</Button>
            </ModalFooter>
          </Container>
        }
      </ModalBody>
    </div>
  )
}

export default EditHealthInsurance;