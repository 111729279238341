import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Table, ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, ModalFooter, Tooltip, } from 'reactstrap';
import axios from 'axios';
import { authHeader } from '../../services/fack.backend';
import Loader from '../../layout/loader';
import Swal from 'sweetalert2';



const UserTable = (props) => {

  const [users, setUsers] = useState({})
  const [loading, setLoading] = useState(true)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [singleUser, setSingleUser] = useState([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [type, setType] = useState('')
  const [isEditing, setEditing] = useState(false)
  const [isCreating, setCreating] = useState(false)
  const [nameToCreate, setNameToCreate] = useState('')
  const [emailToCreate, setEmailToCreate] = useState('')
  const [passwordToCreate, setPasswordToCreate] = useState('')
  const [typeToCreate, setTypeToCreate] = useState('')
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    name: ''
  })
  const [editToolTip, seteditToolTip] = useState(false);
  const [deleteToolTip, setdeleteToolTip] = useState(false);
  const toggleEdit = () => seteditToolTip(!editToolTip);
  const toggleDelete = () => setdeleteToolTip(!deleteToolTip);

  const deleteUser = (user) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o usuário ${user.name}`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/user/` + user.id, { headers: authHeader() }).then(response => {
          Swal.fire('Usuário deletado com sucesso!', '', 'success')
          fetchUsers()
        }).catch(error => {
          Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
        })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  const editUser = async (id) => {
    setEditing(true)
    const bodyData = {
      name: name,
      email: email,
      password: password,
      type: type
    }

    axios.put(`${process.env.REACT_APP_API_URL}/api/user/` + id, bodyData, { headers: authHeader() }).then(response => {
      setEditing(false)
      setIsOpenEdit(false)
      fetchUsers()
      Swal.fire('Usuário editado com sucesso!', '', 'success')
    }).catch(err => {
      Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
    })
  }

  const createUser = async () => {
    setCreating(true)
    const bodyData = {
      email: emailToCreate,
      password: passwordToCreate,
      name: nameToCreate,
      type: typeToCreate
    }
    await axios.post(`${process.env.REACT_APP_API_URL}/api/user/register`, bodyData, { headers: authHeader() }).then(response => {
      setCreating(false)
      setIsOpenCreate(false)
      Swal.fire('Usuário criado com sucesso!', '', 'success')
      fetchUsers()
    }).catch(error => {
      setCreating(false)
      setErrors({
        email: error.response.data.errors.email ? error.response.data.errors.email[0] : '',
        password: error.response.data.errors.password ? error.response.data.errors.password[0] : '',
        name: error.response.data.errors.name ? error.response.data.errors.name[0] : ''
      })
    })
  }

  const fetchUsers = async () => {
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/user/all`, { headers: authHeader() }).then((response) => {
      setUsers(response.data)
      setLoading(false)
    })
  }

  const onOpenEditModal = (userId) => {
    setIsOpenEdit(true);
    users.forEach((user) => {
      if (user.id === userId) {
        setSingleUser(user)
      }
    })
  };

  const onCloseEditModal = () => {
    setIsOpenEdit(false)
  };

  const onOpenCreateModal = () => {
    setIsOpenCreate(true)
  };

  const onCloseCreateModal = () => {
    setIsOpenCreate(false)
  };

  useEffect(() => {
    fetchUsers()
  }, [])


  return (
    <Fragment>
      <Breadcrumb parent="Usuários" title="Lista de Usuários" />
      <Container fluid={true}>
        <Modal isOpen={isOpenEdit} size="lg">
          <ModalHeader toggle={onCloseEditModal}>
            {'Editar Usuário'}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label className="col-form-label">{"Nome:"}</Label>
                <Input className="form-control" type="text" onChange={(e) => setName(e.target.value)} defaultValue={singleUser.name} />
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Email:"}</Label>
                <Input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} defaultValue={singleUser.email}></Input>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Tipo:"}</Label>
                <Input type="select" name="select" className="form-control btn-square" onChange={(e) => setType(e.target.value)} defaultValue={singleUser.type}>
                  <option>admin</option>
                  <option>operacional</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Senha:"}</Label>
                <Input className="form-control" type={"text"} onChange={(e) => setPassword(e.target.value)} defaultValue={singleUser.password} required="" />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={onCloseEditModal}>Fechar</Button>
            <Button color="success" onClick={() => editUser(singleUser.id)}>{isEditing ? "Editando..." : "Salvar"}</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isOpenCreate} size="lg">
          <ModalHeader toggle={onCloseCreateModal}>
            {'Criar Usuário'}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label className="col-form-label">{"Nome:"}</Label>
                <Input className="form-control" type="text" onChange={(e) => setNameToCreate(e.target.value)} />
                <span className="font-danger">{errors.name}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Email:"}</Label>
                <Input type="email" className="form-control" onChange={(e) => setEmailToCreate(e.target.value)}></Input>
                <span className="font-danger">{errors.email}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Tipo:"}</Label>
                <Input type="select" name="select" className="form-control btn-square" onChange={(e) => setTypeToCreate(e.target.value)}>
                  <option disabled selected value> -- Selecione uma Opção -- </option>
                  <option>admin</option>
                  <option>operacional</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Senha:"}</Label>
                <Input className="form-control" type={"text"} onChange={(e) => setPasswordToCreate(e.target.value)} required="" />
                <span className="font-danger">{errors.password && 'Senha é obrigatória'}</span>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={onCloseCreateModal}>Fechar</Button>
            <Button color="success" onClick={() => createUser()}>{isCreating ? "Criando..." : "Salvar"}</Button>
          </ModalFooter>
        </Modal>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{"Usuários"}</h5>
                    <span> {"Veja todos os usuários, crie, edite ou delete-os."}</span>
                    <Button className="pull-right" color='primary' onClick={() => onOpenCreateModal()}><i className="fa fa-plus"></i> Usuário</Button>
                  </CardHeader>
                  {loading ?
                    <div className="loader-box">
                      < div className="loader-7" ></div >
                    </div >
                    :
                    <div className="table-responsive">
                      <Table className="table-border-horizontal">
                        <thead>
                          <tr>
                            <th scope="col">{"Nome"}</th>
                            <th scope="col">{"Tipo"}</th>
                            <th scope="col">{"Email"}</th>
                            <th scope="col">{""}</th>
                          </tr>
                        </thead>
                        {users.map(value => (
                          <tbody>
                            <tr>
                              <td>{value.name}</td>
                              <td>{value.type}</td>
                              <td>{value.email}</td>
                              <td className="table-action-button">
                                <i id="edit-button" className="fa fa-pencil" onClick={() => onOpenEditModal(value.id)} style={{ cursor: "pointer" }}></i>
                                <i id="delete-button" className="fa fa-trash-o" onClick={() => deleteUser(value)} style={{ cursor: "pointer" }}></i>
                              </td>
                            </tr>
                          </tbody>
                        ))
                        }
                      </Table>
                    </div>
                  }
                </Card>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default UserTable;