import React from "react";
import { Button, Container, Form, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ViewInsuranceProcedure = (props) => {
  const { onCloseModal, singleProcedure } = props;

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Visualizar PRocedimento de Convênio'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Form>
            <Label className="col-form-label">{"Código:"}</Label>
            <Input className="form-control" type="text" disabled defaultValue={singleProcedure.procedure.code}/>
            <Label className="col-form-label">{"Descrição:"}</Label>
            <Input className="form-control" type="text" disabled defaultValue={singleProcedure.procedure.description} />
            <Label className="col-form-label">{"Porte:"}</Label>
            <Input className="form-control" type="text" disabled defaultValue={singleProcedure.size.description} />
            <Label className="col-form-label">{"Convênio:"}</Label>
            <Input className="form-control" type="text" disabled defaultValue={singleProcedure.health_insurance.name} />
            <Label className="col-form-label">{"Valor:"}</Label>
            <Input className="form-control" type="text" disabled defaultValue={singleProcedure.value} />
          </Form>
          <ModalFooter>
            <Button color="danger" className="float-right" onClick={() => onCloseModal()}>{'Fechar'}</Button>
          </ModalFooter>
        </Container>
      </ModalBody>
    </div>
  )
}

export default ViewInsuranceProcedure;