import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Col, Container, Modal, Row, Table } from "reactstrap";
import { authHeader } from "../../services/fack.backend";
import Swal from "sweetalert2";
import moment from 'moment';
import CreateHoliday from "./form/create/createHoliday";
import EditHoliday from "./form/edit/editHoliday";
import 'moment/locale/pt-br'

const HolidaysTable = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [holidays, setHolidays] = useState([])
  const [singleHoliday, setSingleHoliday] = useState({})
  let timerInterval;

  useEffect(() => {
    moment.locale('pt-br')
    getHolidays();
  }, [])

  const getHolidays = async () => {
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/holidays`, { headers: authHeader() })
      .then(res => {
        setHolidays(res.data)
        setIsLoading(false)
      }).catch(err => {
        console.log(err)
      })
  }

  const importHolidays = async () => {
    axios.get('https://brasilapi.com.br/api/feriados/v1/2023')
      .then(res => {
        const temp = res.data
        Swal.fire({
          title: 'Feriados sendo importados!',
          html: 'Por Favor Aguarde!',
          timer: 10000,
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })
        axios.post(`${process.env.REACT_APP_API_URL}/api/holidays/import`, temp, { headers: authHeader() })
          .then(res => {
            getHolidays()
            Swal.fire('Sucesso', 'Feriados Nacionais importados com sucesso', 'success');
          }).catch(err => {
            Swal.fire('Error', "Ocorreu um erro ao importar os feriados, tente novamente!", 'error');
          })
      }).catch(err => {
        Swal.fire('Error', "Ocorreu um erro ao importar os feriados, tente novamente!", 'error');
      })
  }

  const deleteHoliday = (holiday) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o Feriado ${holiday.name}`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/holidays/` + holiday.id, { headers: authHeader() }).then(response => {
          Swal.fire('Feriado deletado com sucesso!', '', 'success')
          getHolidays()
        }).catch(error => {
          Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
        })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  const openCreateModal = () => {
    setIsOpenCreateModal(true)
  }

  const closeCreateModal = () => {
    setIsOpenCreateModal(false)
  }

  const openEditModal = (value) => {
    setSingleHoliday(value)
    setIsOpenEditModal(true)
  }

  const closeEditModal = () => {
    setIsOpenEditModal(false)
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Modal isOpen={isOpenCreateModal}>
          <CreateHoliday
            onClose={closeCreateModal}
            getHolidays={getHolidays}
          />
        </Modal>
        <Modal isOpen={isOpenEditModal}>
          <EditHoliday
            onClose={closeEditModal}
            getHolidays={getHolidays}
            singleHoliday={singleHoliday}
          />
        </Modal>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{"Feriados"}</h5>
                    <span> {"Veja todos os Feriados, crie, edite ou delete-os."}</span>
                    <div className="pull-right">
                      <Button className="mr-5" color='primary' onClick={() => openCreateModal()}><i className="fa fa-plus"></i> Feriado</Button>
                      <Button color='success' onClick={() => importHolidays()} ><i className="fa fa-plus"></i> Importar Feriados</Button>
                    </div>
                  </CardHeader>
                  {isLoading
                    ?
                    <div className="loader-box">
                      <div className="loader-7"></div>
                    </div>
                    :
                    <div className="table-responsive">
                      <Table className="table-border-horizontal">
                        <thead>
                          <tr>
                            <th scope="col">{"Nome"}</th>
                            <th scope="col">{"Tipo"}</th>
                            <th scope="col">{"Data"}</th>
                            <th scope="col">{""}</th>
                            <th scope="col">{""}</th>
                          </tr>
                        </thead>
                        {holidays.map((value, key) => (
                          <tbody>
                            <tr key={key}>
                              <td key={key}>{value.name}</td>
                              <td key={key}>{value.type}</td>
                              <td key={key}>{moment(value.date).format('L')}</td>
                              <td className="table-action-button">
                                <i id={`edit-button`} className="fa fa-pencil" onClick={() => openEditModal(value)} style={{ cursor: "pointer" }} ></i>
                                <i id={`delete-button`} className="fa fa-trash-o" onClick={() => deleteHoliday(value)} style={{ cursor: "pointer" }} ></i>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  }
                </Card>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default HolidaysTable;