import axios from "axios";
import React, { useState } from "react";
import { Button, Container, Form, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../../services/fack.backend";

const EditHoliday = (props) => {
  const { onClose, getHolidays, singleHoliday } = props
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [date, setDate] = useState('')

  const editHoliday = async () => {
    const formData = {
      name: name === "" ? singleHoliday.name : name,
      type: type === "" ? singleHoliday.type : type,
      date: date === "" ? singleHoliday.date : date
    }
    await axios.put(`${process.env.REACT_APP_API_URL}/api/holidays/${singleHoliday.id}`, formData, { headers: authHeader() })
    .then(res => {
      Swal.fire('Sucesso', 'Feriado editado com sucesso', 'success');
      getHolidays()
      onClose()
    }).catch(err => {
      Swal.fire('Error', 'Ocorreu um erro, tente novamente!', 'error');
      onClose()
    })
  }

  return (
    <div>
      <ModalHeader toggle={onClose}>
        <h5>{'Editar Feriado'}</h5>

      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Form>
            <Label className="col-form-label">{"Nome:"}</Label>
            <Input className="form-control" type="text" defaultValue={singleHoliday.name} onChange={(e) => setName(e.target.value)} />
            <span className="font-danger">{errors.name}</span>
            <Label className="col-form-label">{"Tipo:"}</Label>
            <Input className="form-control" type="text" defaultValue={singleHoliday.type} onChange={(e) => setType(e.target.value)} />
            <span className="font-danger">{errors.type}</span>
            <Label className="col-sm-3 col-form-label">{"Data:"}</Label>
            <Input className="form-control digits" type="date" defaultValue={singleHoliday.date} onChange={(e) => setDate(e.target.value)}/>
            <span className="font-danger">{errors.date}</span>
          </Form>
          <ModalFooter>
            <Button color="primary" className="float-right" onClick={() => editHoliday()} >{'Editar'}</Button>
          </ModalFooter>
        </Container>
      </ModalBody>
    </div>
  )
}

export default EditHoliday;