import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from "reactstrap";
import InsuranceProcedure from "./insuranceProcedure/insuranceProcedure";
import Sizes from "./size/sizes";
import EmergencyDays from "./emergencyDays/emergencyDays";
import Acomodation from "./acomodation/acomodation";

const DetailInsurance = (props) => {
  const params = useParams();
  const [activeTab, setActiveTab] = useState('1');
  const [isLoading, setIsLoading] = useState(false)
  const [procedures, setProcedures] = useState([])

  useEffect(() => {
    console.log()
  }, [])

  return (
    <React.Fragment>
      <Container fluid={true} >
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{`Detalhes do Convênio`}</h5>
              </CardHeader>
              <CardBody>
                <div className="tabbed-card">
                  <Nav className="pull-right  nav-pills nav-primary">
                    <NavItem>
                      <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')} style={{ cursor: "pointer" }}>
                        {`Procedimentos`}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')} style={{ cursor: "pointer" }}>
                        {`Portes`}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')} style={{ cursor: "pointer" }}>
                        {`Emergência`}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('4')} style={{ cursor: "pointer" }}>
                        {`Acomodações`}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <InsuranceProcedure
                        insurance_id={params.id}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Sizes
                        insurance_id={params.id}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <EmergencyDays
                        insurance_id={params.id}
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <Acomodation
                        insurance_id={params.id}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

export default DetailInsurance;