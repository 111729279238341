import axios from "axios";
import React, { useState } from "react";
import { Button, Container, Form, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { authHeader } from "../../../../services/fack.backend";
import Swal from "sweetalert2";

const CreateParticipationDegree = (props) => {
  const {onCloseModal, fetchDegrees } = props

  const [percentage, setPercentage] = useState()
  const [degree, setDegree] = useState()

  const createDegree = async () => {
    const formData = {
      degree: degree,
      percentage: percentage
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/api/participationDegree`, formData, { headers: authHeader() })
    .then(res => {
      Swal.fire('Sucesso', 'Grau de Participação criado com sucesso', 'success')
      onCloseModal()
      fetchDegrees()
    }).catch(err => {
      Swal.fire('Error', 'Ocorreu um erro, por favor tente novamente', 'error')
      onCloseModal()
    })
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Cadastrar Grau de Participação'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Form>
            <Label className="col-form-label">{"Grau:"}</Label>
            <Input className="form-control" type="text" onChange={(e) => setDegree(e.target.value)} />
            <Label className="col-form-label">{"Porcentagem:"}</Label>
            <Input className="form-control" type="number" onChange={(e) => setPercentage(e.target.value)} />
          </Form>
          <ModalFooter>
            <Button color="primary" className="float-right" onClick={() => createDegree()} >{'Criar'}</Button>
          </ModalFooter>
        </Container>
      </ModalBody>
    </div>
  )
}

export default CreateParticipationDegree;