import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, Col, Container, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import axios from "axios";
import { authHeader } from "../../../services/fack.backend";
import Swal from 'sweetalert2';

const ManipulateAppointment = (props) => {
  const { currentAppointment, goBack, hasManipulation, removeAppointment, fetchAppointments } = props
  const [expandedRows, setExpandedRows] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [glosaReason, setGlosaReason] = useState('')
  const [date, setDate] = useState('')
  const [appointmentStatus, setStatus] = useState('')
  const [appointmentToEdit, setAppointmentToEdit] = useState({})

  useEffect(() => {
    if (hasManipulation == 1) {
      setIsLoading(true)
      fetchManipulationValues()
    }
  }, [currentAppointment])

  const status = {
    "waiting_analitica": "AGUARDANDO ANALITICA",
    "analysis": "PARA ANALISE",
    "checked_by_system": "CONFERIDO PELO SISTEMA",
    "glosa_analysis": "ANALISE DE GLOSA",
    "glosa_refusal": "RECUSA DE GLOSA",
    "approved": "APROVADO",
    "paid_for_resource_glosa":"PAGO POR RECURSO DE GLOSA"
  }

  const fetchManipulationValues = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/manipulationValues/${currentAppointment.id}`, { headers: authHeader() })
      .then(res => {
        setAppointmentToEdit(res.data)
        setIsLoading(false)
      }).catch(err => {
        console.log(err)
      })
  }

  const handleSave = async () => {
    if (hasManipulation == 1) {
      appointmentToEdit.status = appointmentStatus !== '' ? appointmentStatus : appointmentToEdit.status
      appointmentToEdit.has_manipulation = 1;

      await axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/manipulate`, appointmentToEdit, { headers: authHeader() })
        .then(res => {
          Swal.fire('Sucesso', 'Manipulação Concluída com Sucesso!', 'success')
        }).catch(err => {
          console.log(err)
        })
    } else {
      currentAppointment.status = appointmentStatus !== '' ? appointmentStatus : 'waiting_analitica'
      await axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/manipulate`, currentAppointment, { headers: authHeader() })
        .then(res => {
          Swal.fire('Sucesso', 'Manipulação Concluída com Sucesso!', 'success')
        }).catch(err => {
          console.log(err)
        })
    }
  }

  const rowExpansionTemplate = (data) => {
    return (
      <div>
        <FormGroup className="row">
          <Label className="col-sm-3 col-form-label">{"Data do Pagamento:"}</Label>
          <Col sm="9">
            <Input className="form-control digits" type="date" defaultValue={hasManipulation ? data.manipulationValues.date : data.date} onChange={(e) => handleChangeDate(data, e.target.value)} />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-sm-3 col-form-label">{"Motivo de Glosa:"}</Label>
          <Col sm="9">
            <Input className="form-control digits" type="textarea" defaultValue={hasManipulation ? data.manipulationValues.glosa_reason : data.glosa_reason} onChange={(e) => handleChangeGlosaReason(data, e.target.value)} />
          </Col>
        </FormGroup>
      </div>
    );
  };

  const handleChangeDate = (data, value) => {
    if (hasManipulation) {
      data.manipulationValues.date = value
    } else {
      data.date = value
    }
  }

  const handleChangeGlosaReason = (data, value) => {
    if (hasManipulation) {
      data.manipulationValues.glosa_reason = value
    } else {
      data.glosa_reason = value
    }
  }

  const valueBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.value);
  };

  const payedBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.manipulationValues.payed);
  };

  const glosaBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.manipulationValues.glosa);
  };

  const smallDiffBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.manipulationValues.smallDiff);
  };

  const bigDiffBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.manipulationValues.bigDiff);
  };

  const cellEditor = (options) => {
    if (options.field === "manipulationValues.payed") return priceEditor(options);
    else return textEditor(options);
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case 'manipulationValues.payed':
        const bigOrSmallDiff = compareValue(newValue, rowData['value'])
        if (bigOrSmallDiff.diff === 'big') {
          rowData['manipulationValues']['payed'] = newValue
          rowData['manipulationValues']['bigDiff'] = bigOrSmallDiff.newValue
          rowData['manipulationValues']['smallDiff'] = 0
          rowData['manipulationValues']['glosa'] = 0
          break;
        } else {
          rowData['manipulationValues']['payed'] = newValue
          rowData['manipulationValues']['smallDiff'] = bigOrSmallDiff.newValue
          rowData['manipulationValues']['glosa'] = bigOrSmallDiff.newValue
          rowData['manipulationValues']['bigDiff'] = 0

        }
    }
  };

  const priceEditor = (options) => {
    return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="BRL" locale="pt-BR" />;
  };

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const compareValue = (newValue, value) => {
    let newV = String(newValue);
    let v = String(value);
    newV = newV.trim();
    v = v.trim();
    if (!newV) {
      return false;
    }

    newV = newV.replace(/^0+/, '') || '0';
    v = v.replace(/^0+/, '') || '0';
    let n1 = Number(newV);
    let n2 = Number(v);

    if (n1 >= n2) {
      const bigOrSmallDiff = {
        'diff': 'big',
        'newValue': n1 - n2
      }

      return bigOrSmallDiff
    } else {
      const bigOrSmallDiff = {
        'diff': 'small',
        'newValue': n2 - n1
      }

      return bigOrSmallDiff
    }
  };

  const editAppointment = (id) => {
    window.open(
      `${process.env.REACT_APP_URL}/app/appointment/edit/${id}/Dubai`,
      '_blank'
    )
  }

  return (
    <div>
      <Card>
        <CardBody>
          <div className="row">
            <Col sm="4 col-4">
              <h6>{`Paciente`}</h6>
              <h3 className="counter">{currentAppointment.patient.name}</h3>
            </Col>
            <Col sm="4 col-4">
              <h6>{`Convênio`}</h6>
              <h3><span className="counter">{currentAppointment.insurance.name}</span></h3>
            </Col>
            <Col sm="4 col-4">
              <h6>{`Data`}</h6>
              <h3><span className="counter">{moment(currentAppointment.date).format('L')}</span></h3>
            </Col>
          </div>
        </CardBody>
      </Card>
      <div className="card">
        {isLoading ?
          <div className="loader-box">
            <div className="loader-7"></div>
          </div>
          :
          <DataTable value={hasManipulation ? appointmentToEdit.procedures : currentAppointment.procedures} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate} dataKey="id" tableStyle={{ minWidth: '60rem' }} editMode="cell">
            <Column expander style={{ width: '5rem' }} />
            <Column field="procedure.code" header="Código" />
            <Column field="procedure.description" header="Procedimento" />
            <Column field="value" header="Valor Calculado" body={valueBodyTemplate} />
            <Column field={"manipulationValues.payed"} body={payedBodyTemplate} header="Valor Pago" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
            <Column field={"manipulationValues.glosa"} body={glosaBodyTemplate} header="Valor Glosa" />
            <Column field={"manipulationValues.smallDiff"} body={smallDiffBodyTemplate} header="Diferença Menor" />
            <Column field={"manipulationValues.bigDiff"} body={bigDiffBodyTemplate} header="Diferença Maior" />
          </DataTable>
        }
        <CardFooter >
          <div className="input-group mb-3">
            <Input type="select" onChange={(e) => setStatus(e.target.value)}>
              {Object.entries(status).map((value) => (
                hasManipulation && appointmentToEdit.status === value[0] ?
                  <option key={value[0]} selected value={value[0]}>{value[1]}</option>
                  :
                  <option key={value[0]} value={value[0]}>{value[1]}</option>

              ))}
            </Input>
            <div className="input-group-prepend">
              <Button onClick={() => goBack()} color="primary">Voltar</Button>
            </div>
          </div>

        </CardFooter>
        <div className="action-float">
          <div className="dropup-basic ">
            <Button color="primary" onClick={() => editAppointment(currentAppointment.id)} className="dropbtn">
              <i className="fa fa-pencil-square-o">
              </i>
            </Button>
          </div>
          <span onClick={() => handleSave()} className="bg-success"><i className="fa fa-save"></i></span>
        </div>
      </div>
    </div>
  )
}

export default ManipulateAppointment;