import axios from "axios";
import React, { useEffect, useState } from "react";
import { authHeader } from "../../../../services/fack.backend";
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import EditEmergencyDay from "../emergencyDays/form/edit/editEmergencyDay";
import EditAcomodation from "./form/edit/editAcomodation";

const Acomodation = (props) => {
  const { insurance_id } = props
  const [isLoading, setIsLoading] = useState(true)
  const [acomodations, setAcomodations] = useState([])
  const [acomodation, setAcomodation] = useState([])
  const [isOpenEdit, setIsOpenEdit] = useState(false)

  useEffect(() => {
    fetchAcomodations();
  }, [])

  const fetchAcomodations = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/healthInsuranceAcomodation/getAcomodations/${insurance_id}`, { headers: authHeader() })
      .then(res => {
        setAcomodations(res.data)
        setIsLoading(false)
      })
  }

  const openEdit = (acomodation) => {
    setAcomodation(acomodation)
    setIsOpenEdit(true)
  }

  const onCloseEdit = () => {
    setIsOpenEdit(false)
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Modal isOpen={isOpenEdit}>
          <EditAcomodation
            onCloseModal={onCloseEdit}
            fetchAcomodations={fetchAcomodations}
            insurance_id={insurance_id}
            acomodation={acomodation}
          />
        </Modal>
        {isLoading ?
          <div className="loader-box">
            <div className="loader-7"></div>
          </div>
          :
          <CardBody>
            <FormGroup className="row">
              {acomodations.map((acomodation) => (
                <Col className="col-4">
                  <Card style={{ cursor: "pointer" }} onClick={() => openEdit(acomodation)}>
                    <CardBody>
                      <Row>
                        <h6 className="text-center">{`${acomodation.description}`}</h6>
                        <Col>
                          <Label className="col-form-label">{"Vezes(x) cobradas:"}</Label>
                          <Input disabled defaultValue={acomodation.value} type="number" />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </FormGroup>
          </CardBody>
        }
      </Container>
    </React.Fragment >
  )
}

export default Acomodation;