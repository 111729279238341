import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { Card, CardHeader, Col, Modal, Row } from "reactstrap";
import { Tag } from 'primereact/tag';
import FilterBilling from "./filterComponent/statusDropdownComponent";
import ImportAnalitica from "./importAnalitica/importAnalitica";

const DataTableComponent = (props) => {
  const { appointments, manipulateAppointments, status, setFilterOnAppointments, copyAppointments, fetchAppointments } = props
  const [selectedAppointments, setSelectedAppointments] = useState([])
  const [isFilter, setIsFilter] = useState(false)
  const [isOpenImport, setIsOpenImport] = useState(false)

  const formatDate = (value) => {
    return value.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.date);
  };

  const exportAppointments = (appointments) => {
    let temp = 'id=';
    appointments.map((appointment, index) => {
      if (index == 0) {
        temp = temp.concat(`${appointment.id}`)
      } else {
        temp = temp.concat(',', `${appointment.id}`)
      }
    })
    window.location.href = `${process.env.REACT_APP_API_URL}/api/appointment/export?${temp}`
  }

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button type="button" label="Importar Analítica" className="m-l-5" outlined onClick={() => openImport()} />
        {selectedAppointments.length > 0 &&
          <Button type="button" label="Exportar" className="m-l-5" outlined onClick={() => exportAppointments(selectedAppointments)} />
        }
        {selectedAppointments.length > 0 &&
          <Button type="button" label="Manipular" className="m-l-5" outlined onClick={() => manipulateAppointments(selectedAppointments)} />
        }
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    let statusTranslated = status.find(current => current.value == rowData.status);
    return <Tag value={statusTranslated.description} severity={statusTranslated.severity} />;
  };


  const [visibleColumns] = useState([
    { field: 'guideNumber', header: 'Guia' },
    { field: 'patient.name', header: 'Paciente', sortable: true },
    { field: 'insurance.name', header: 'Convênio', sortable: true },
    { field: 'doctor.name', header: 'Médico', sortable: true },
    { field: 'clinic.name', header: 'Clínica', sortable: true },
    { field: 'appointmentType', header: 'Tipo do Agendamento', sortable: true }
  ]);

  const header = renderHeader();

  const openImport = () => setIsOpenImport(true)

  const closeImport = () => setIsOpenImport(false)

  return (
    <Row>
      <Modal isOpen={isOpenImport}>
        <ImportAnalitica
          onCloseModal={closeImport}
          fetchAppointments={fetchAppointments}
        />
      </Modal>
      <Col sm="12">
        <Card>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{"Faturamentos"}</h5>
                <span> {"Veja todos os Faturamentos"}</span>
              </CardHeader>
              <FilterBilling setIsFilter={(data) => setIsFilter(data)} setFilterOnAppointments={(data) => setFilterOnAppointments(data)} />
              <div className="card">
                <DataTable
                  value={isFilter ? copyAppointments : appointments}
                  paginator
                  rows={10}
                  showGridlines
                  tableStyle={{ minWidth: '50rem' }}
                  header={header}
                  selectionMode={'checkbox'}
                  selection={selectedAppointments}
                  onSelectionChange={(e) => setSelectedAppointments(e.value)}
                  dataKey="id"
                >
                  <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                  {visibleColumns.map((col) => (
                    <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable ? col.sortable : false} />
                  ))}
                  <Column header="Data" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} sortField={"date"} sortable />
                  <Column header="Status" filterField="status" style={{ minWidth: '10rem' }} body={statusBodyTemplate} />
                </DataTable>
              </div>
            </Card>
          </Col>
        </Card>
      </Col >
    </Row >
  )
}

export default DataTableComponent;