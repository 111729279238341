import axios from "axios";
import React, { useState } from "react";
import { Button, Container, Form, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../../services/fack.backend";

const CreateHoliday = (props) => {
  const { onClose, getHolidays } = props
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [date, setDate] = useState('')

  const createHoliday = async () => {
    const formData = {
      name: name,
      type: type,
      date: date
    }
    await axios.post(`${process.env.REACT_APP_API_URL}/api/holidays`, formData, { headers: authHeader() })
    .then(res => {
      Swal.fire('Sucesso', 'Feriado cadastrado com sucesso', 'success');
      getHolidays()
      onClose()
    }).catch(err => {
      Swal.fire('Error', 'Ocorreu um erro, tente novamente!', 'error');
      onClose()
    })
  }

  return (
    <div>
      <ModalHeader toggle={onClose}>
        <h5>{'Cadastrar Feriado'}</h5>

      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Form>
            <Label className="col-form-label">{"Nome:"}</Label>
            <Input className="form-control" type="text" onChange={(e) => setName(e.target.value)} />
            <span className="font-danger">{errors.name}</span>
            <Label className="col-form-label">{"Tipo:"}</Label>
            <Input className="form-control" type="text" onChange={(e) => setType(e.target.value)} />
            <span className="font-danger">{errors.type}</span>
            <Label className="col-sm-3 col-form-label">{"Data:"}</Label>
            <Input className="form-control digits" type="date" onChange={(e) => setDate(e.target.value)}/>
            <span className="font-danger">{errors.date}</span>
          </Form>
          <ModalFooter>
            <Button color="primary" className="float-right" onClick={() => createHoliday()} >{'Criar'}</Button>
          </ModalFooter>
        </Container>
      </ModalBody>
    </div>
  )
}

export default CreateHoliday;