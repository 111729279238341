import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Col, Container, Input, Modal, Row, Table } from "reactstrap";
import { authHeader } from "../../services/fack.backend";
import Swal from "sweetalert2";
import ImportAppointments from "./import/importAppointments";
import StaticLoader from "../../layout/static-loader";

const AppointmentTable = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [isOpenImportModal, setIsOpenImportModal] = useState(false)
  const [nextEndpointPage, setNextEndpointPage] = useState(`${process.env.REACT_APP_API_URL}/api/appointment?page=1`);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchNextPage, setSearchNextPage] = useState(null);

  useEffect(() => {
    fetchAppointments();
  }, [])


  useEffect(() => {
    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !isLoading) {
        if (searchTerm) {
          fetchAppointments(searchTerm, true);
        } else {
          fetchAppointments();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, nextEndpointPage, searchTerm, searchNextPage]);

  const fetchAppointments = async (search = "", isScroll = false) => {
    if (isLoading) return;

    let url = search ? searchNextPage || `${process.env.REACT_APP_API_URL}/api/appointment?page=1&search=${search}` : nextEndpointPage;

    if (url.includes('null'))
      return;

    setIsLoading(true);

    try {
      const res = await axios.get(url, { headers: authHeader() });
      if (search) {
        setAppointments(prev => isScroll ? [...prev, ...res.data.data] : res.data.data);
        setSearchNextPage(`${res.data.next_page_url}&search=${search}`);
      } else {
        setAppointments(prev => [...prev, ...res.data.data]);
        setNextEndpointPage(res.data.next_page_url);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  const openEditAppointment = (id) => {
    window.location.href = `${process.env.REACT_APP_URL}/app/appointment/edit/${id}/Dubai`
  }

  const deleteAppointment = async (appointment) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o Agendamento`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/appointment/` + appointment.id, { headers: authHeader() }).then(response => {
          Swal.fire('Agendamento deletado com sucesso!', '', 'success')
          fetchAppointments()
        }).catch(error => {
          Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
        })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  const openImportModal = () => setIsOpenImportModal(true)

  const closeImportModal = () => setIsOpenImportModal(false)

  const handleSearch = (e) => {
    if (e.key !== 'Enter')
      return;

    const term = e.target.value;

    if (term.length === 0)
      window.location.reload()

    setSearchTerm(term);

    if (term.length >= 3) {
      setSearchNextPage(`${process.env.REACT_APP_API_URL}/api/appointment?page=1&search=${term}`);
      fetchAppointments(term);
    } else {
      setSearchNextPage(null);
    }
  };


  return (
    <React.Fragment>
      {isLoading && <StaticLoader />}

      <Container fluid={true}>
        <Modal isOpen={isOpenImportModal}>
          <ImportAppointments
            onCloseModal={closeImportModal}
            fetchAppointments={() => window.location.reload()}
          />
        </Modal>
        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{"Agendamentos"}</h5>
                    <span> {"Veja todos os Agendamentos"}</span>
                    <div className="pull-right">
                      <div className="search d-flex justify-content-end">
                        <div>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Pesquisar"
                            onKeyDown={(e) => handleSearch(e)}
                          />
                        </div>
                        <Button color='success' onClick={() => openImportModal()}><i className="fa fa-plus"></i> Importar</Button>
                      </div>
                    </div>
                  </CardHeader>
                  <div className="table-responsive">
                    <Table hover className="table-border-horizontal">
                      <thead>
                        <tr>
                          <th scope="col">{"Número da Guia"}</th>
                          <th scope="col">{"Paciente"}</th>
                          <th scope="col">{"Convênio"}</th>
                          <th scope="col">{"Médico"}</th>
                          <th scope="col">{"Tipo do Agendamento"}</th>
                          <th scope="col">{"Total"}</th>
                          <th scope="col">{""}</th>
                        </tr>
                      </thead>
                      {appointments.map((value, key) => (
                        <tbody>
                          <tr key={key}>
                            <td key={key}>{value.guideNumber}</td>
                            <td key={key}>{value.patient.name}</td>
                            <td key={key}>{value.insurance.name}</td>
                            <td key={key}>{value.doctor.name}</td>
                            <td key={key}>{value.appointmentType}</td>
                            <td key={key}>{value.total}</td>
                            <td className="table-action-button">
                              <i className="fa fa-pencil" style={{ cursor: "pointer" }} onClick={() => openEditAppointment(value.id)}></i>
                              <i className="fa fa-trash-o" style={{ cursor: "pointer" }} onClick={() => deleteAppointment(value)}></i>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>
                  </div>
                </Card>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default AppointmentTable;