// dashbaord
import UserTable from '../components/users/userTable'
import DoctorTable from '../components/doctors/doctorsTable'
import ClinicTable from '../components/clinic/clinicTable'
import PatientTable from '../components/patient/patientTable'
import HealthInsuranceTable from '../components/healthInsurance/healthInsuranceTable'
import Appointment from '../components/appointment/appointment'
import GlobalProcedure from '../components/procedure/globalProcedure/globalProcedure'
import SizeTable from '../components/sizes/sizeTable'
import DetailInsurance from '../components/healthInsurance/details/detailInsurance'
import HolidaysTable from '../components/holidays/holidaysTable'
import ParticipationDegreeTable from '../components/participationDegree/participationDegreeTable'
import AppointmentTable from '../components/appointment/appointmentTable'
import ViewAppointment from '../components/appointment/view/viewAppointment'
import BillingTable from '../components/billing/billingTable'



export const routes = [
        //Usuários
        { path: `/app/users/userTable/:layout`, Component: <UserTable /> },

        // //Médicos
        { path: `/app/doctors/doctorTable/:layout`, Component: <DoctorTable /> },

        //Clínicas
        { path: `/app/clinics/clinicTable/:layout`, Component: <ClinicTable /> },

        //Convênios
        { path: `/app/healthInsurance/healthInsuranceTable/:layout`, Component: <HealthInsuranceTable /> },
        { path: `/app/healthInsurance/healthInsuranceTable/details/:id/:layout`, Component: <DetailInsurance /> },

        //Pacientes
        { path: `/app/patient/patientTable/:layout`, Component: <PatientTable /> },

        //Agendamento
        { path: `/app/appointment/:layout`, Component: <Appointment /> },
        { path: `/app/appointment/edit/:id/:layout`, Component: <Appointment /> },
        { path: `/app/appointmentTable/:layout`, Component: <AppointmentTable /> },
        // { path: `/app/appointment/edit/:id/:layout`, Component: <EditAppointment /> },
        { path: `/app/appointment/details/:id/:layout`, Component: <ViewAppointment /> },


        //Portes
        { path: `/app/sizes/:layout`, Component: <SizeTable /> },

        //Procedimentos
        { path: `/app/globalProcedures/:layout`, Component: <GlobalProcedure /> },
        
        //Feriados
        { path: `/app/holidays/:layout`, Component: <HolidaysTable /> },

        //Graus de Participação
        { path: `/app/participation_degree/:layout`, Component: <ParticipationDegreeTable/>},


        { path: `/app/billing/:layout`, Component: <BillingTable/>},
]