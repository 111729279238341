import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { firebase_app } from '../data/config';
import { authHeader, handleResponse } from '../services/fack.backend';

const PrivateRoute = () => {
    const token = localStorage.getItem('token');

    return (
        token ?
            <Outlet />
            :
            <Navigate exact to={`/login`} />
    );
}

export default PrivateRoute;

