import axios from "axios";
import React, { useState } from "react";
import { Button, Container, Input, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../services/fack.backend";

const ImportAppointments = (props) => {
  const { onCloseModal, fetchAppointments } = props;
  const [file, setFile] = useState()

  const handleFile = (file) => {
    setFile(file)
  }

  let timerInterval;
  const handleFileSubmit = async () => {
    try {
      Swal.fire({
        title: 'Arquivo sendo importado!',
        html: 'Por Favor Aguarde!',
        timer: 20000,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })

      let data = new FormData();
      data.append("file_name", file);

      await axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/import`, data, { headers: authHeader() })
        .then(response => {
          fetchAppointments()
          onCloseModal()
          Swal.fire('Arquivo importado com sucesso!', '', 'success');
        }).catch(err => {
          onCloseModal()
          Swal.fire('Erro', err.response.data, 'error');
        })
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Importar Agendamentos'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container>
          <div className="d-flex justify-content-center" >
            <Input
              type="file"
              accept="*/*"
              onChange={file => { handleFile(file.target.files[0]) }}
            />
            <Button color="success" onClick={() => handleFileSubmit()}>
              Importar
            </Button>
          </div>
        </Container>
      </ModalBody>
    </div>
  )
}

export default ImportAppointments;