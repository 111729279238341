import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { authHeader } from "../../../../../../services/fack.backend";
import Swal from "sweetalert2";

const CreateEmergencyDay = (props) => {
  const { onCloseModal, insurance_id, fetchDays } = props
  const [day, setDay] = useState('')
  const [percentage, setPercentage] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')

  const daysOfTheWeek = {
    "business_days": "Dias Úteis",
    "weekend": "Finais de Semana",
    "holidays": "Feriados",
  }

  const createDay = async () => {
    const formData = {
      day: day,
      percentage: percentage,
      start_time: startTime,
      end_time: endTime
    }
    await axios.post(`${process.env.REACT_APP_API_URL}/api/emergencyDays/${insurance_id}`, formData, { headers: authHeader() })
      .then(res => {
        Swal.fire('Sucesso', 'Dia de Emergência cadastrado com sucesso', 'success');
        onCloseModal()
        fetchDays()
      }).catch(err => {
        Swal.fire('Error', err.response.data, 'error');
        onCloseModal()
      })
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Cadastrar Dia de Emergência'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Row>
            <Col>
              <Label className="col-form-label">{"Dia da Semana:"}</Label>
              <div>
                <Input className="form-control" type="select" onChange={(e) => setDay(e.target.value)}>
                  <option disabled selected value></option>
                  {Object.entries(daysOfTheWeek).map((day, key) => (
                    <option key={key} value={day[0]}>{day[1]}</option>
                  ))}
                </Input>
              </div>
            </Col>
            <Col>
              <Label className="col-form-label">{"Porcentagem:"}</Label>
              <Input className="form-control" type="number" max={100} onChange={(e) => setPercentage(e.target.value)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="col-form-label">{"Horário Inicial:"}</Label>
              <Input className="form-control digits" type="time" onChange={(e) => setStartTime(e.target.value)}/>
            </Col>
            <Col>
              <Label className="col-form-label">{"Horário Final:"}</Label>
              <Input className="form-control digits" type="time" onChange={(e) => setEndTime(e.target.value)} />
            </Col>
          </Row>
        </Container>
        <ModalFooter>
          <Button color="primary" className="float-right" onClick={() => createDay()}>{'Criar'}</Button>
        </ModalFooter>
      </ModalBody>
    </div>
  )
}

export default CreateEmergencyDay;