import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../../../../services/fack.backend";


const CreateSizeInsurance = (props) => {
  const { onCloseModal, insurance_id, fetchSizes } = props

  const [isLoadingInputSize, setIsLoadingInputSize] = useState(true)
  const [isLoadingInputHealthInsurance, setIsLoadingInputHealthInsurance] = useState(true)

  const [value, setValue] = useState(undefined)
  const [size, setSize] = useState('')
  const [sizes, setSizes] = useState({});


  useEffect(() => {
    fetchSizesGlobal();
  }, [])

  const createSize = async () => {
    const formData = {
      health_insurance_id: insurance_id,
      size_id: size,
      value: value
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/api/sizeInsurance`, formData, { headers: authHeader() })
      .then(res => {
        onCloseModal()
        fetchSizes();
        Swal.fire('Porte do Convênio criado com sucesso', '', 'success');

      }).catch(err => {
        onCloseModal()
        Swal.fire('Ocorreu um erro, tente novamente', '', 'error')
      })
  }


  const fetchSizesGlobal = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/sizes`, { headers: authHeader() })
      .then(res => {
        setSizes(res.data)
        setIsLoadingInputSize(false)
      })
  }

  const onAmountChange = e => {
    let value = e.target.value
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1.$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ",");

    setValue(value)

    e.target.value = value
    return e
  };

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Cadastrar Porte do Convênio'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Label className="col-form-label">{"Porte:"}</Label>
          {isLoadingInputSize
            ?
            <Col>
              <div className="loader-box">
                <div className="loader-4"></div>
              </div>
            </Col>
            :
            <div>
              <Input className="form-control" type="select" onChange={(e) => setSize(e.target.value)}>
                <option disabled selected value></option>
                {sizes.map(size => (
                  <option key={size.id} value={size.id}>{size.description}</option>
                ))}
              </Input>
            </div>
          }
          <Label className="col-form-label">{"Valor:"}</Label>
          <Input className="form-control" type="text" value={value} onChange={(e) => onAmountChange(e)} />
        </Container>
        <ModalFooter>
          <Button color="primary" className="float-right" onClick={() => createSize()}>{'Criar'}</Button>
        </ModalFooter>
      </ModalBody>
    </div>
  )
}

export default CreateSizeInsurance;