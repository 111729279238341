import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { authHeader } from "../../../../../../services/fack.backend";
import Swal from "sweetalert2";

const EditEmergencyDay = (props) => {
  const { onCloseModal, insurance_id, fetchDays, singleDay, deleteDay } = props
  const [percentage, setPercentage] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')


  const editDay = async () => {
    const formData = {
      percentage: percentage === '' ? singleDay.percentage : percentage,
      start_time: startTime === '' ? singleDay.initial : startTime,
      end_time: endTime === '' ? singleDay.final : endTime
    }
    await axios.put(`${process.env.REACT_APP_API_URL}/api/emergencyDays/${singleDay.id}`, formData, { headers: authHeader() })
      .then(res => {
        Swal.fire('Sucesso', 'Dia de Emergência editado com sucesso', 'success');
        onCloseModal()
        fetchDays()
      })
  }

  useEffect(() => {
  }, [])

  const openDelete = () => {
    onCloseModal()
    deleteDay(singleDay)
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Editar Dia de Emergência'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Row>
            <Col>
              <Label className="col-form-label">{"Dia da Semana:"}</Label>
              <div>
                <Input className="form-control" disabled defaultValue={singleDay.day.description}/>
              </div>
            </Col>
            <Col>
              <Label className="col-form-label">{"Porcentagem:"}</Label>
              <Input className="form-control" type="number" max={100} defaultValue={singleDay.percentage} onChange={(e) => setPercentage(e.target.value)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="col-form-label">{"Horário Inicial:"}</Label>
              <Input className="form-control digits" type="time" defaultValue={singleDay.initial} onChange={(e) => setStartTime(e.target.value)} />
            </Col>
            <Col>
              <Label className="col-form-label">{"Horário Final:"}</Label>
              <Input className="form-control digits" type="time" defaultValue={singleDay.final} onChange={(e) => setEndTime(e.target.value)} />
            </Col>
          </Row>
        </Container>
        <ModalFooter>
          <Button color="danger" className="float-right" onClick={() => openDelete()}>{'Deletar'}</Button>
          <Button color="primary" className="float-right" onClick={() => editDay()}>{'Editar'}</Button>
        </ModalFooter>
      </ModalBody>
    </div>
  )
}

export default EditEmergencyDay;