import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { authHeader } from "../../../services/fack.backend";


const ViewDoctor = (props) => {
  const { onCloseModal, singleDoctor } = props
  const [isLoadingDoctor, setLoadingDoctor] = useState(false)
  const [specialty, setSpecialty] = useState([])
  const [clinics, setClinics] = useState([])


  useEffect(() => {
    fetchDoctorSpecialties()
  }, [])

  const fetchDoctorSpecialties = async () => {
    setLoadingDoctor(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/doctor-specialty/${singleDoctor.id}`, { headers: authHeader() }).then((response) => {
      setSpecialty(response.data)
      fetchDoctorClinics()
    })
  }

  const fetchDoctorClinics = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/doctor-clinic/${singleDoctor.id}`, { headers: authHeader() })
      .then((response) => {
        setClinics(response.data)
        setLoadingDoctor(false)
      })
  }



  return (
    <div>

      <ModalHeader toggle={onCloseModal}>
        {"Visualizar Médico"}
      </ModalHeader>
      {isLoadingDoctor ?
        <div className="loader-box">
          <div className="loader-7"></div>
        </div>
        :
        <div>
          <ModalBody>
            <Card >
              <CardBody>
                <Form>
                  <Row className="mb-2">
                    <div className="profile-title">
                      <h4 className="f-w-100">{singleDoctor.name}</h4>
                      <p className="txt-primary">{singleDoctor.crm}</p>
                    </div>
                  </Row>
                  <br />
                  <FormGroup>
                    <Label className="form-label">{'Email'}</Label>
                    <Input className="form-control" disabled="true" defaultValue={singleDoctor.email} />
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">{'Telefone'}</Label>
                    <Input className="form-control" disabled="true" defaultValue={singleDoctor.phone} />
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">{'Clínicas'}</Label>
                    <ListGroup>
                      {clinics.map(value => (
                        <ListGroupItem>{value.clinic.name}</ListGroupItem>
                      ))}
                    </ListGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">{'Especialidades'}</Label>
                    <ListGroup>
                      <ListGroupItem>{specialty.name}</ListGroupItem>
                    </ListGroup>
                  </FormGroup>
                  <FormGroup>
                    <h6 className="form-label">{'Observação'}</h6>
                    <Input type="textarea" disabled="true" className="form-control" rows="5" defaultValue={singleDoctor.observation} />
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={onCloseModal}>Fechar</Button>
          </ModalFooter>
        </div>
      }
    </div>
  )
}


export default ViewDoctor