import axios from "axios";
import React, { useState } from "react";
import { Button, Container, Input, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../../services/fack.backend";

const ImportProcedure = (props) => {
  const { onCloseModal, isGlobal } = props;
  const [file, setFile] = useState()

  const handleFile = (file) => {
    setFile(file)
  }

  let timerInterval;
  const handleFileSubmit = async () => {
    try {
      Swal.fire({
        title: 'Arquivo sendo importado!',
        html: 'Por Favor Aguarde!',
        timer: 20000,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading()
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })

      let data = new FormData();
      data.append("file_name", file);

      if (isGlobal) {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/globalProcedure/import`, data, { headers: authHeader() })
          .then(response => {
            onCloseModal()
            Swal.fire('Arquivo importado com sucesso!', '', 'success');
          })
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/insuranceProcedure/import`, data, { headers: authHeader() })
          .then(response => {
            onCloseModal()
            Swal.fire('Arquivo importado com sucesso!', '', 'success');
          }).catch(err => {
            onCloseModal()
            Swal.fire(err.response.data.message, 'Corrija o ID e tente novamente!', 'error');
          })
      }
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{isGlobal ? 'Importar Procedimentos Globais' : 'Importar Procedimentos do Convênio'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container>
          <div className="d-flex justify-content-center" >
            <Input
              type="file"
              accept="*/*"
              onChange={file => { handleFile(file.target.files[0]) }}
            />
            <Button color="success" onClick={() => handleFileSubmit()}>
              Importar
            </Button>
          </div>
        </Container>
      </ModalBody>
    </div>
  )
}

export default ImportProcedure;