import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, ModalFooter, Row } from 'reactstrap';
import axios from 'axios';
import Board, { moveCard } from '@asseinfo/react-kanban';

const Step3 = (props) => {
  const { formInfo, setInfo, previousStep, acomodations, isEdit } = props

  const [errors, setErrors] = useState({})
  const [controlledBoard, setBoard] = useState(acomodations);

  useEffect(() => {
  }, [])

  const nextStep = () => {
    if(isEdit){
      setInfo(controlledBoard.columns)
    } else {
      setInfo(controlledBoard.columns[1].cards)
    }
  }


  function ControlledBoard() {
    // You need to control the state yourself.

    function handleCardMove(_card, source, destination) {
      const updatedBoard = moveCard(controlledBoard, source, destination);
      setBoard(updatedBoard);
    }


    return (
      <Board onCardDragEnd={handleCardMove} disableColumnDrag>
        {controlledBoard}
      </Board>
    );
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Form>
          <Card className="card-absolute">
            <CardHeader className="bg-primary">
              <h5>{'Passo 3'}</h5>
            </CardHeader>
            <CardBody>
              <ControlledBoard />
            </CardBody>
          </Card>
        </Form>
        <ModalFooter>
          <Button color="primary" className="float-left" onClick={() => previousStep()}>Voltar</Button>
          <Button color="primary" className="float-right" onClick={() => nextStep()}>{isEdit ? 'Editar' : 'Criar'}</Button>
        </ModalFooter>
      </Container>
    </Fragment>
  );
};

export default Step3;