import axios from "axios";
import React, { useState } from "react";
import { Button, Container, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../../../../services/fack.backend";

const EditSizeInsurance = (props) => {
  const { onCloseModal, size, fetchSizes } = props
  const [value, setValue] = useState(undefined)

  const onAmountChange = e => {
    let value = e.target.value
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1.$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ",");

    setValue(value)

    e.target.value = value
    return e
  };

  const editSize = async () => {
    const formData = {
      value: value
    }

    axios.post(`${process.env.REACT_APP_API_URL}/api/sizeInsurance/${size.id}`, formData, { headers: authHeader() })
      .then(res => {
        onCloseModal()
        fetchSizes()
        Swal.fire(`Porte ${size.size.description} editado com sucesso`, '', 'success')
      }).catch(err => {
        onCloseModal()
        Swal.fire('Ocorreu um erro, tente novamente', '', 'error')
      })
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{`Editar Porte ${size.size.description}`}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Label className="col-form-label">{"Valor:"}</Label>
          <Input className="form-control" type="text" value={value === undefined ? size.value : value} onChange={(e) => onAmountChange(e)} />
        </Container>
        <br />
        <ModalFooter>
          <Button color="primary" className="float-right" onClick={() => editSize()}>{'Editar'}</Button>
        </ModalFooter>
      </ModalBody>
    </div>
  )
}

export default EditSizeInsurance;