import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Container, Form, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Board, { moveCard } from '@asseinfo/react-kanban';
import axios from "axios";
import { authHeader } from "../../../../services/fack.backend";
import Swal from "sweetalert2";

const CreateHealthInsurance = (props) => {
  const { onCloseModal, acomodations, fetchHealthInsurances } = props;
  const [controlledBoard, setBoard] = useState(acomodations);
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [errors, setErrors] = useState({})

  const createHealthInsurance = async () => {
    if (name === '') {
      setErrors({
        name: 'Nome é obrigatório!'
      })
    } else if(type === '') {
      setErrors({
        type: 'Tipo é obrigatório!'
      })
    } else {
      const formData = {
        name: name,
        type: type,
        acomodations: controlledBoard.columns[1].cards
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/api/healthInsurance/create`, formData, { headers: authHeader() })
        .then(response => {
          fetchHealthInsurances()
          onCloseModal()
          Swal.fire('Convênio criado com sucesso!', '', 'success')
        }).catch(err => {
          console.log(err)
        })
    }
  }


  function ControlledBoard() {

    function handleCardMove(_card, source, destination) {
      const updatedBoard = moveCard(controlledBoard, source, destination);
      setBoard(updatedBoard);
    }

    return (
      <Board onCardDragEnd={handleCardMove} disableColumnDrag>
        {controlledBoard}
      </Board>
    );
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Cadastrar Convênio'}</h5>

      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Form>
            <Label className="col-form-label">{"Nome:"}</Label>
            <Input className="form-control" type="text" onChange={(e) => setName(e.target.value)} />
            <span className="font-danger">{errors.name}</span>
            <Label className="col-form-label">{"Tipo:"}</Label>
            <Input className="form-control" type="text" onChange={(e) => setType(e.target.value)} />
            <span className="font-danger">{errors.type}</span>
            <ControlledBoard />
          </Form>
          <ModalFooter>
            <Button color="primary" className="float-right" onClick={() => createHealthInsurance()} >{'Criar'}</Button>
          </ModalFooter>
        </Container>
      </ModalBody>
    </div>
  )
}

export default CreateHealthInsurance;