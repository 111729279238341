import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader, generateUUID } from "../../../../services/fack.backend";
import LinkHealthInsurance from "../../../healthInsurance/link/linkHealthInsurance";
import ViewLinkedHealthInsurance from "../view/viewLinkedHealthInsurance";
import ViewEditLinkedHealthInsurance from "./view/viewEditLinkedHealthInsurance";

const EditPatient = (props) => {
  const { onCloseModal, fetchPatients, singlePatient } = props;

  const [name, setName] = useState(singlePatient.name)
  const [errors, setErrors] = useState({})

  const [isLoading, setIsLoading] = useState(true)
  const [isOpenHealthInsuranceModal, setIsOpenHealthInsuranceModal] = useState(false)
  const [isOpenViewModal, setIsOpenViewModal] = useState(false)
  const [isOpenEditModal, setIsEditViewModal] = useState(false)

  const [linkedHealthInsurances, setLinkedHealthInsurances] = useState([])
  const [singleInsurance, setSingleInsurance] = useState({})
  const [healthInsurances, setHealthInsurances] = useState([])
  const [acomodationsToEdit, setAcomodationsToEdit] = useState([])


  useEffect(() => {
    fetchLinkedAcomodations();
    fetchHealthInsurances();
  }, [])

  const editPatient = async () => {
    const formData = {
      name: name,
      healthInsurances: linkedHealthInsurances
    }
    await axios.post(`${process.env.REACT_APP_API_URL}/api/patient/edit/${singlePatient.id}`, formData, { headers: authHeader() })
      .then(res => {
        Swal.fire('Paciente editado com sucesso!', '', 'success')
        fetchPatients()
        onCloseModal()
      }).catch(err => {
        console.log(err)
      })
  }

  const fetchLinkedAcomodations = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/patientInsurance/getInsurances/${singlePatient.id}`, { headers: authHeader() })
      .then(res => {
        setLinkedHealthInsurances(res.data)
      }).catch(err => {
        console.log(err)
      })
  }

  const fetchHealthInsurances = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/healthInsurance/getAll`, { headers: authHeader() })
      .then(res => {
        setHealthInsurances(res.data)
        setIsLoading(false)
      }).catch(err => {
        console.log(err)
      })
  }

  const isOpenHealthInsurance = () => {
    setIsOpenHealthInsuranceModal(true)
  };
  const onCloseHealthInsurance = () => {
    setIsOpenHealthInsuranceModal(false)
  };

  const onOpenViewModal = (value) => {
    setSingleInsurance(value)
    setIsOpenViewModal(true)
  }

  const onCloseViewModal = () => {
    setIsOpenViewModal(false)
  }

  const onOpenEditModal = (value, key) => {
    setSingleInsurance({ ...value, key: value.key })

    let includedNames = []

    Object.values(value.acomodations).map(response => {
      includedNames.push(response.description)
    })

    let temp = {
      columns: [
        {
          id: 1,
          title: 'Não Inclusas',
          cards: acomodations.columns[0].cards.filter(card => !includedNames.includes(card.description))
        },
        {
          id: 2,
          title: 'Inclusas',
          cards: value.acomodations
        },
      ]
    }
    setAcomodationsToEdit(temp)
    setIsEditViewModal(true)
  }

  const onCloseEditModal = () => {
    setIsEditViewModal(false)
  }

  const deleteLinkedHealthInsurance = async (value) => {
    const temp = []
    linkedHealthInsurances.map(v => {
      if (v !== value) {
        temp.push(v)
      }
    })
    setLinkedHealthInsurances(temp)
  }

  const getLinkedAcomodations = (data) => {
    const temp = linkedHealthInsurances.filter(insurance => insurance.key != data.key);
    temp.push({
      health_insurance: data.selectedInsurance[0],
      patient_insurance: {
        enrollment: data.enrollment
      },
      acomodations: data.acomodations,
      key: data.key
    });
    setLinkedHealthInsurances(temp);
    setErrors({})
  }

  const acomodations = {
    columns: [
      {
        id: 1,
        title: 'Não Inclusas',
        cards: [
          {
            id: generateUUID(),
            description: 'Ambulatório'
          },
          {
            id: generateUUID(),
            description: 'Apartamento'
          },
          {
            id: generateUUID(),
            description: 'Enfermaria'
          },
        ]
      },
      {
        id: 2,
        title: 'Inclusas',
        cards: []
      },
    ]
  }

  return (
    <div>
      <Modal isOpen={isOpenHealthInsuranceModal} size="lg">
        <LinkHealthInsurance
          onCloseModal={onCloseHealthInsurance}
          acomodations={acomodations}
          getLinkedAcomodations={(data) => getLinkedAcomodations(data)}
          healthInsurances={healthInsurances}
          isEdit={false}
        />
      </Modal>

      <Modal isOpen={isOpenViewModal} size="sm">
        <ViewEditLinkedHealthInsurance
          onCloseModal={onCloseViewModal}
          singleInsurance={singleInsurance}
        />
      </Modal>

      <Modal isOpen={isOpenEditModal} size="lg">
        <LinkHealthInsurance
          onCloseModal={onCloseHealthInsurance}
          acomodations={acomodationsToEdit}
          getLinkedAcomodations={(data) => getLinkedAcomodations(data)}
          healthInsurances={healthInsurances}
          isEdit={true}
          singleInsurance={singleInsurance}
          onCloseEditModal={onCloseEditModal}
        />
      </Modal>

      <ModalHeader toggle={onCloseModal}>
        <h5>{'Editar Paciente'}</h5>
      </ModalHeader>
      {isLoading ?
        <div className="loader-box">
          <div className="loader-7"></div>
        </div>
        :
        <ModalBody>
          <Container fluid={true}>
            <Form>
              <Row>
                <Col>
                  <Label className="col-form-label">{"Nome:"}</Label>
                  <Input className="form-control" type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} />
                  <span className="font-danger">{errors.name}</span>
                </Col>
              </Row>
              <br />
              {linkedHealthInsurances.length > 0 &&
                <div className="table-responsive">
                  <Table className="table-border-horizontal">
                    <thead>
                      <tr>
                        <th scope="col">{"Convênio"}</th>
                        <th scope="col">{"Matrícula"}</th>
                        <th scope="col">{""}</th>
                        <th scope="col">{""}</th>
                      </tr>
                    </thead>
                    {linkedHealthInsurances.map((value, key) => (
                      <tbody>
                        <tr key={key}>
                          <td>{value.health_insurance.name}</td>
                          <td>{value.patient_insurance.enrollment}</td>
                          <td>
                            <i id="look-button" className="fa fa-eye" onClick={() => onOpenViewModal(value)} style={{ cursor: "pointer" }}></i>
                          </td>
                          <td>
                            <i id={`edit-button`} className="fa fa-pencil" onClick={() => onOpenEditModal(value)} style={{ cursor: "pointer" }}></i>
                          </td>
                          <td>
                            <i id={`delete-button`} className="fa fa-trash-o" onClick={() => deleteLinkedHealthInsurance(value)} style={{ cursor: "pointer" }}></i>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                </div>
              }
              <br />
              <Row className="text-center">
                <Col className="col-12">
                  <Button color="success" onClick={() => isOpenHealthInsurance()}><i className="fa fa-plus"></i> Vincular Convênio</Button>
                </Col>
                <span className="font-danger">{errors.linkedHealthInsurances}</span>
              </Row>
            </Form>
            <br />
            <ModalFooter>
              <Button color="primary" className="float-right" onClick={() => editPatient()} >{'Editar'}</Button>
            </ModalFooter>
          </Container>
        </ModalBody>
      }
    </div>
  )
}

export default EditPatient;