import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Col, Container, Input, Modal, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../../services/fack.backend";
import ImportProcedure from "../../../procedure/globalProcedure/importProcedure/importProcedure";
import CreateInsuranceProcedure from "./form/create/createInsuranceProcedure";
import EditInsuranceProcedure from "./form/edit/editInsuranceProcedure";
import ViewInsuranceProcedure from "./view/viewInsuranceProcedure";

const InsuranceProcedure = (props) => {
  const { insurance_id } = props

  const [isLoading, setIsLoading] = useState(true);
  const [procedures, setProcedures] = useState({})
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [filteredProcedures, setFilteredProcedures] = useState([])
  const [isOpenViewModal, setIsOpenViewModal] = useState(false)
  const [singleProcedure, setSingleProcedure] = useState({})

  const openCreateModal = () => {
    setIsOpenCreateModal(true)
  }

  const closeCreateModal = () => {
    setIsOpenCreateModal(false)
  }

  const openEditModal = (value) => {
    setSingleProcedure(value)
    setIsOpenEditModal(true)
  }

  const closeEditModal = () => {
    setIsOpenEditModal(false)
  }

  const openViewModal = (value) => {
    setSingleProcedure(value)
    setIsOpenViewModal(true)
  }

  const closeViewModal = () => {
    setIsOpenViewModal(false)
  }

  useEffect(() => {
    fetchProcedures()
  }, [])

  const fetchProcedures = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/insuranceProcedure/${insurance_id}`, { headers: authHeader() })
      .then(res => {
        setProcedures(res.data)
        setFilteredProcedures(res.data)
        setIsLoading(false)
      })
  }

  const deleteProcedure = async (procedure) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o Procedimento de Convênio ${procedure.procedure.description}`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/insuranceProcedure/` + procedure.id, { headers: authHeader() }).then(response => {
          Swal.fire('Procedimento de Convênio deletado com sucesso!', '', 'success')
          fetchProcedures()
        }).catch(error => {
          Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
        })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  const handleSearch = (e) => {

    const temp = procedures.filter((item) => {
      return item.procedure.code.toLowerCase().indexOf(e.toLowerCase()) !== -1;
    });

    setFilteredProcedures(temp)
  }

  return (
    <React.Fragment>
      <Container fluid={true}>

        <Modal isOpen={isOpenCreateModal}>
          <CreateInsuranceProcedure
            onCloseModal={closeCreateModal}
            fetchProcedures={fetchProcedures}
            insurance_id={insurance_id}
          />
        </Modal>
        <Modal isOpen={isOpenEditModal}>
          <EditInsuranceProcedure
            onCloseModal={closeEditModal}
            fetchProcedures={fetchProcedures}
            singleProcedure={singleProcedure}
          />
        </Modal>
        <Modal isOpen={isOpenViewModal}>
          <ViewInsuranceProcedure
            onCloseModal={closeViewModal}
            singleProcedure={singleProcedure}
          />
        </Modal>
        <Row>
          <Col sm="12">
            {isLoading
              ?
              <div className="loader-box">
                <div className="loader-7"></div>
              </div>
              :
              <div className="table-responsive">
                <div className="pull-right">
                  <Button className="mr-5" color='primary' onClick={() => openCreateModal()}><i className="fa fa-plus"></i> Procedimento de Convênio</Button>
                  <div className="search">
                    <div className="mb-3">
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Pesquise um Código"
                        defaultValue={searchKeyword}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <Table className="table-border-horizontal">
                  <thead>
                    <tr>
                      <th scope="col">{"Código"}</th>
                      <th scope="col">{"Descrição"}</th>
                      <th scope="col">{"Porte"}</th>
                      <th scope="col">{"Valor"}</th>
                      <th scope="col">{""}</th>
                      <th scope="col">{""}</th>
                      <th scope="col">{""}</th>
                    </tr>
                  </thead>
                  {filteredProcedures.map((value, key) => (
                    <tbody>
                      <tr key={key}>
                        <td key={key}>{value.procedure.code}</td>
                        <td key={key}>{value.procedure.description}</td>
                        <td key={key}>{value.size ? value.size.description : ''}</td>
                        <td key={key}>{value.value}</td>
                        <td className="table-action-button">
                          <i id="look-button" className="fa fa-eye" style={{ cursor: "pointer" }} onClick={() => openViewModal(value)}></i>
                          <i id={`edit-button`} className="fa fa-pencil" style={{ cursor: "pointer" }} onClick={() => openEditModal(value)}></i>
                          <i id={`delete-button`} className="fa fa-trash-o" style={{ cursor: "pointer" }} onClick={() => deleteProcedure(value)}></i>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </div>
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  )
}

export default InsuranceProcedure;