import axios from "axios";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, CardHeader, Col, Container, Input, Modal, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../services/fack.backend";
import CreatePatient from "./form/create/createPatient";
import EditPatient from "./form/edit/editPatient";
import ViewPatient from "./form/view/viewPatient";
import StaticLoader from "../../layout/static-loader";

const PatientTable = (props) => {
  const [singlePatient, setSinglePatient] = useState({});
  const [patients, setPatients] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nextEndpointPage, setNextEndpointPage] = useState(`${process.env.REACT_APP_API_URL}/api/patient/getPatients?page=1`);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchNextPage, setSearchNextPage] = useState(null);

  useEffect(() => {
    fetchPatients();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !isLoading) {
        if (searchTerm) {
          fetchPatients(searchTerm, true);
        } else {
          fetchPatients();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, nextEndpointPage, searchTerm, searchNextPage]);

  const fetchPatients = async (search = "", isScroll = false) => {
    if (isLoading) return;

    let url = search ? searchNextPage || `${process.env.REACT_APP_API_URL}/api/patient/getPatients?page=1&search=${search}` : nextEndpointPage;

    if (url.includes('null'))
      return;

    setIsLoading(true);

    try {
      const res = await axios.get(url, { headers: authHeader() });
      if (search) {
        setPatients(prevPatients => isScroll ? [...prevPatients, ...res.data.data] : res.data.data);
        setSearchNextPage(`${res.data.next_page_url}&search=${search}`);
      } else {
        setPatients(prevPatients => [...prevPatients, ...res.data.data]);
        setNextEndpointPage(res.data.next_page_url);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenCreateModal = () => setIsOpenCreate(true);
  const onCloseCreateModal = () => setIsOpenCreate(false);
  const onOpenViewModal = (value) => {
    setSinglePatient(value);
    setIsOpenView(true);
  };
  const onCloseViewModal = () => setIsOpenView(false);
  const onOpenEditModal = (value) => {
    setSinglePatient(value);
    setIsOpenEdit(true);
  };
  const onCloseEditModal = () => setIsOpenEdit(false);

  const deletePatient = async (patient) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o Paciente ${patient.name}`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/patient/delete/${patient.id}`, { headers: authHeader() })
          .then(response => {
            Swal.fire('Paciente deletado com sucesso!', '', 'success');
            setPatients(patients.filter(p => p.id !== patient.id));
          })
          .catch(error => {
            Swal.fire('Ocorreu um erro, tente novamente!', '', 'error');
          });
      }
    });
  };

  const handleSearch = (e) => {
    if (e.key !== 'Enter')
      return;


    const term = e.target.value;

    if (term.length === 0)
      window.location.reload()

    setSearchTerm(term);

    if (term.length >= 3) {
      setSearchNextPage(`${process.env.REACT_APP_API_URL}/api/patient/getPatients?page=1&search=${term}`);
      fetchPatients(term);
    } else {
      setSearchNextPage(null);
    }
  };

  return (
    <React.Fragment>
      {isLoading && <StaticLoader />}
      <Breadcrumb parent="Convênios" title="Lista de Convênios" />
      <Container fluid={true}>
        <Modal isOpen={isOpenCreate} size="lg">
          <CreatePatient
            onCloseModal={onCloseCreateModal}
            fetchPatients={fetchPatients}
          />
        </Modal>

        <Modal isOpen={isOpenView} size="lg">
          <ViewPatient
            onCloseModal={onCloseViewModal}
            singlePatient={singlePatient}
          />
        </Modal>

        <Modal isOpen={isOpenEdit} size="lg">
          <EditPatient
            onCloseModal={onCloseEditModal}
            singlePatient={singlePatient}
            fetchPatients={fetchPatients}
          />
        </Modal>

        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Pacientes</h5>
                <span>Veja todos os pacientes, crie, edite ou delete-os.</span>
                <div className="search d-flex justify-content-end">
                  <div>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Pesquisar"
                      onKeyDown={(e) => handleSearch(e)}
                    />
                  </div>

                  <Button color='primary' onClick={onOpenCreateModal}><i className="fa fa-plus"></i> Paciente</Button>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="table-border-horizontal">
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {patients.map((value, key) => (
                      <tr key={key}>
                        <td>{value.name}</td>
                        <td className="table-action-button">
                          <i className="fa fa-eye" onClick={() => onOpenViewModal(value)} style={{ cursor: "pointer" }}></i>
                          <i className="fa fa-pencil" onClick={() => onOpenEditModal(value)} style={{ cursor: "pointer" }}></i>
                          <i className="fa fa-trash-o" onClick={() => deletePatient(value)} style={{ cursor: "pointer" }}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default PatientTable;
