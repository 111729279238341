import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { authHeader } from "../../../../../../services/fack.backend";

const EditAcomodation = (props) => {
  const { onCloseModal, acomodation, insurance_id, fetchAcomodations } = props

  const [value, setValue] = useState('')

  const editAcomodation = async () => {
    const formData = {
      value: value === "" ? acomodation.value : value
    }
    await axios.post(`${process.env.REACT_APP_API_URL}/api/healthInsuranceAcomodation/${acomodation.id}`, formData, { headers: authHeader() })
      .then(res => {
        fetchAcomodations()
        onCloseModal()
      })
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Editar Acomodação'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Row>
            <Col>
              <Label className="col-form-label">{"Acomodação:"}</Label>
              <div>
                <Input className="form-control" disabled defaultValue={acomodation.description} />
              </div>
            </Col>
            <Col>
              <Label className="col-form-label">{"Vezes(x) cobradas:"}</Label>
              <Input className="form-control" type="number" max={100} defaultValue={acomodation.value} onChange={(e) => setValue(e.target.value)} />
            </Col>
          </Row>
          <br />
        </Container>
        <ModalFooter>
          <Button color="primary" className="float-right" onClick={() => editAcomodation()} >{'Editar'}</Button>
        </ModalFooter>
      </ModalBody>
    </div>
  )
}

export default EditAcomodation;