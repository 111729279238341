import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Table, Button, Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { authHeader } from '../../services/fack.backend';
import Loader from '../../layout/loader';
import Swal from 'sweetalert2';
import EditDoctor from './edit/editDoctors';
import ViewDoctor from './view/viewDoctor';



const DoctorTable = (props) => {

  const [doctors, setDoctors] = useState({})

  const [loading, setLoading] = useState(true)
  const [isLoadingInput, setIsLoadingInput] = useState(true)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [filteredDoctors, setFilteredDoctors] = useState([])


  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [isOpenView, setIsOpenView] = useState(false)

  const [singleDoctor, setSingleDoctor] = useState([])


  const [isCreating, setCreating] = useState(false)

  const [nameToCreate, setNameToCreate] = useState('')
  const [emailToCreate, setEmailToCreate] = useState('')
  const [crmToCreate, setCrmToCreate] = useState('')
  const [phoneToCreate, setPhoneToCreate] = useState('')
  const [observationToCreate, setObservationToCreate] = useState('')
  const [specialtyToCreate, setSpecialtyToCreate] = useState('')
  const [specialtiesToCreate, setSpecialtiesToCreate] = useState([])
  const [clinicsToCreate, setClinicsToCreate] = useState([])
  const [clinicToCreate, setClinicToCreate] = useState({})
  const [clinics, setClinics] = useState({})

  const [errors, setErrors] = useState({
    name: '',
    crm: '',
  })
  const [errorSpecialty, setErrorSpecialty] = useState('')
  const [errorClinic, setErrorClinic] = useState('')

  const deleteDoctor = (doctor) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o Médico ${doctor.name}`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/doctor/delete/` + doctor.id, { headers: authHeader() }).then(response => {
          Swal.fire('Médico deletado com sucesso!', '', 'success')
          fetchDoctors()
        }).catch(error => {
          Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
        })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  const createDoctor = async () => {
    setCreating(true)
    setErrors({})
    if (nameToCreate === "") {
      setErrors({
        name: "Nome é obrigatório!"
      })
      setCreating(false)
      return
    }

    if (crmToCreate === "") {
      setCreating(false)
      setErrors({
        crm: "CRM é obrigatório!"
      })
      return
    }

    const bodyData = {
      email: emailToCreate,
      phone: phoneToCreate,
      name: nameToCreate,
      crm: crmToCreate,
      specialties: specialtiesToCreate,
      observation: observationToCreate,
      clinics: clinicsToCreate
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/api/doctor/register`, bodyData, { headers: authHeader() }).then(response => {
      setCreating(false)
      setIsOpenCreate(false)
      Swal.fire('Médico criado com sucesso!', '', 'success')
      fetchDoctors()
    }).catch(error => {
      setCreating(false)
    })
  }

  const fetchDoctors = async () => {
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/doctor/all`, { headers: authHeader() }).then((response) => {
      setDoctors(response.data)
      setFilteredDoctors(response.data)
      setLoading(false)
    })
  }

  const fetchClinics = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clinic/getClinics`, { headers: authHeader() })
      .then((response) => {
        setClinics(response.data)
        setIsLoadingInput(false)
      })
  }

  const onOpenEditModal = (doctorId) => {
    setIsOpenEdit(true);
    doctors.forEach((doctor) => {
      if (doctor.id === doctorId) {
        setSingleDoctor(doctor)
      }
    })
  };

  const onOpenLookModal = (doctorId) => {
    setIsOpenView(true);
    doctors.forEach((doctor) => {
      if (doctor.id === doctorId) {
        setSingleDoctor(doctor)
      }
    })
  }

  const onCloseEditModal = () => {
    setIsOpenEdit(false)
  };

  const onOpenCreateModal = () => {
    setIsOpenCreate(true)
  };

  const onCloseCreateModal = () => {
    setIsOpenCreate(false)
  };
  const onCloseViewModal = () => {
    setIsOpenView(false)
  };


  useEffect(() => {
    fetchDoctors()
    fetchClinics();
  }, [])

  const addToSpecialtiesToCreateList = () => {
    const temp = specialtiesToCreate
    if (specialtyToCreate === "") {
      setErrorSpecialty('Informe o nome da especialidade')
      return
    } else {
      setErrorSpecialty('')
      temp.push(specialtyToCreate)
      setSpecialtiesToCreate(temp)
      setSpecialtyToCreate('')
    }
  }

  const deleteSpecialtyToCreate = (value) => {
    const temp = []
    specialtiesToCreate.map(v => {
      if (v !== value) {
        temp.push(v)
      }
    })
    setSpecialtiesToCreate(temp)
  }

  const addToClinicsToCreateList = () => {
    setErrorClinic('')
    const temp = clinicsToCreate
    let dontPush = false;
    temp.map(v => {
      if (clinicToCreate == v.id) {
        setErrorClinic('Clínica já adicionada')
        dontPush = true;
        return
      }
    })

    if (!dontPush) {
      clinics.map(v => {
        if (v.id == clinicToCreate) {
          temp.push(v)
        }
      })
      setClinicsToCreate(temp)
      setClinicToCreate('')
    }
  }

  const deletClinicToCreate = (value) => {
    const temp = []
    clinicsToCreate.map(v => {
      if (v !== value) {
        temp.push(v)
      }
    })
    setClinicsToCreate(temp)
  }

  const handleSearch = (e) => {

    const temp = doctors.filter((item) => {
      return item.name.toLowerCase().indexOf(e.toLowerCase()) !== -1;
    });
    setFilteredDoctors(temp)
  }

  return (
    <Fragment>
      <Breadcrumb parent="Médicos" title="Lista de Médicos" />
      <Container fluid={true}>
        <Modal isOpen={isOpenEdit} size="lg">
          <EditDoctor
            onCloseModal={onCloseEditModal}
            singleDoctor={singleDoctor}
            getDoctors={fetchDoctors}
          />
        </Modal>

        <Modal isOpen={isOpenView} size="lg">
          <ViewDoctor
            onCloseModal={onCloseViewModal}
            singleDoctor={singleDoctor}
          />
        </Modal>

        <Modal isOpen={isOpenCreate} size="lg">
          <ModalHeader toggle={onCloseCreateModal}>
            {'Criar Médico'}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label className="col-form-label">{"Nome:"}</Label>
                <Input className="form-control" type="text" onChange={(e) => setNameToCreate(e.target.value)} />
                <span className="font-danger">{errors.name}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"CRM:"}</Label>
                <Input type="text" className="form-control" onChange={(e) => setCrmToCreate(e.target.value)}></Input>
                <span className="font-danger">{errors.crm}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Email:"}</Label>
                <Input type="email" className="form-control" onChange={(e) => setEmailToCreate(e.target.value)}></Input>
                <span className="font-danger">{errors.email}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Telefone:"}</Label>
                <Input type="tel" className="form-control" onChange={(e) => setPhoneToCreate(e.target.value)}></Input>
                <span className="font-danger">{errors.phone}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Clínicas:"}</Label>
                <div className="input-group">
                  {isLoadingInput
                    ?
                    <Col>
                      <div className="loader-box">
                        <div className="loader-4"></div>
                      </div>
                    </Col>
                    :
                    <div className="input-group">
                      <Input className="form-control" type="select" onChange={(e) => setClinicToCreate(e.target.value)}>
                        <option disabled selected value></option>
                        {clinics.map(clinic => (
                          <option value={clinic.id}>{clinic.name}</option>
                        ))}
                      </Input>
                      <Button onClick={() => addToClinicsToCreateList()}><i className="fa fa-plus"></i></Button>
                    </div>
                  }
                  <div>
                    <span className="font-danger">{errorClinic}</span>
                  </div>
                </div>
                <Table className="table-border-horizontal">
                  <thead>
                    <tr>
                      <th scope="col-10">{""}</th>
                      <th scope="col-2">{""}</th>
                    </tr>
                  </thead>
                  {clinicsToCreate.map((value, key) => (
                    <tbody>
                      <tr key={key}>
                        <td>{value.name}</td>
                        <td>
                          <i className="fa fa-trash-o" onClick={() => deletClinicToCreate(value)} style={{ cursor: "pointer" }}></i>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Especialidade:"}</Label>
                <div className="input-group">
                  <Input id="specialty" type="text" className="form-control" value={specialtyToCreate} onChange={(e) => setSpecialtyToCreate(e.target.value)}></Input>
                  <Button onClick={() => addToSpecialtiesToCreateList()}><i className="fa fa-plus"></i></Button>
                </div>
                <div>
                  <span className="font-danger">{errorSpecialty}</span>
                </div>
                <Table className="table-border-horizontal">
                  <thead>
                    <tr>
                      <th scope="col-10">{""}</th>
                      <th scope="col-2">{""}</th>
                    </tr>
                  </thead>
                  {specialtiesToCreate.map((value, key) =>
                    <tbody>
                      <tr key={key}>
                        <td>{value}</td>
                        <td>
                          <i className="fa fa-trash-o" onClick={() => deleteSpecialtyToCreate(value)} style={{ cursor: "pointer" }}></i>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">{"Observação:"}</Label>
                <Input type="textarea" className="form-control" rows="3" onChange={(e) => setObservationToCreate(e.target.value)}></Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={onCloseCreateModal}>Fechar</Button>
            <Button color="success" onClick={() => createDoctor()}>{isCreating ? "Criando..." : "Salvar"}</Button>
          </ModalFooter>
        </Modal>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{"Médicos"}</h5>
                    <span> {"Veja todos os médicos, crie, edite ou delete-os."}</span>
                    <Button className="pull-right" color='primary' onClick={() => onOpenCreateModal()}><i className="fa fa-plus"></i> Médico</Button>
                    <div className="search">
                      <div className="mb-3">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Pesquisar"
                          defaultValue={searchKeyword}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </div>
                    </div> 
                  </CardHeader>
                  <div className="table-responsive">
                    {loading ?
                      <div className="loader-box">
                        <div className="loader-7"></div>
                      </div>
                      :
                      <Table className="table-border-horizontal">
                        <thead>
                          <tr>
                            <th scope="col">{"Nome"}</th>
                            <th scope="col">{"CRM"}</th>
                            <th scope="col">{"Email"}</th>
                            <th scope="col">{"Telefone"}</th>
                            <th scope="col">{""}</th>
                          </tr>
                        </thead>
                        {filteredDoctors.map(value => (
                          <tbody>
                            <tr key={value.id}>
                              <td>{value.name}</td>
                              <td>{value.crm}</td>
                              <td>{value.email}</td>
                              <td>{value.phone}</td>
                              <td className="table-action-button">
                                <i id="look-button" className="fa fa-eye" onClick={() => onOpenLookModal(value.id)} style={{ cursor: "pointer" }}></i>
                                <i id={`edit-button-${value.id}`} className="fa fa-pencil" onClick={() => onOpenEditModal(value.id)} style={{ cursor: "pointer" }}></i>
                                <i id={`delete-button-${value.id}`} className="fa fa-trash-o" onClick={() => deleteDoctor(value)} style={{ cursor: "pointer" }}></i>
                              </td>
                            </tr>
                          </tbody>
                        ))
                        }
                      </Table>
                    }
                  </div>
                </Card>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  );
}

export default DoctorTable;