import React from "react";
import { Col, Container, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Loader from "../../../../layout/loader";
import axios from "axios";
import { authHeader } from "../../../../services/fack.backend";
import Swal from "sweetalert2";

const CreateClinic = (props) => {
  const { onCloseModal, fetchClinics } = props


  const [clinic, setClinic] = useState()
  const [loading, setLoading] = useState(false)

  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)

  const [form1, setForm1] = useState({
    name: '',
    fantasyName: '',
    socialReason: '',
    phone: '',
    email: '',
    stateSubscription: '',
    neighborhoodSubscription: '',
    cnpj: '',
    initials: '',
  })
  const [form2, setForm2] = useState({
    cep: '',
    state: '',
    city: '',
    neighborhood: '',
    address: '',
    number: '',
    complement: '',
    neighborhoodCode: ''
  })
  const [form3, setForm3] = useState([])

  const acomodations = {
    columns: [
      {
        id: 1,
        title: 'Não Inclusas',
        cards: [
          {
            id: 3,
            description: 'Ambulatório'
          },
          {
            id: 4,
            description: 'Apartamento'
          },
          {
            id: 5,
            description: 'Enfermaria'
          },
        ]
      },
      {
        id: 2,
        title: 'Inclusas',
        cards: []
      },
    ]
  }

  const setInfo1 = data => {
    let temp = form1
    for (let [key, value] of Object.entries(temp)) {
      for (const [index, v] of Object.entries(data)) {
        if (key === index) {
          temp[key] = v
        }
      }
    }
    setForm1(temp)
    setStep1(false)
    setStep2(true)
  }

  const setInfo2 = data => {
    let temp = form2
    for (let [key, value] of Object.entries(temp)) {
      for (const [index, v] of Object.entries(data)) {
        if (key === index) {
          temp[key] = v
        }
      }
    }
    setForm2(temp)
    setStep2(false)
    setStep3(true)
  }

  const setInfo3 = async (data) => {
    setIsLoading()
    setStep3(false)

    const form = {
      1: form1,
      2: form2,
      3: data
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/api/clinic/create`, form, { headers: authHeader() })
    .then((response) => {
      Swal.fire('Clínica criada com sucesso!', '', 'success')
      fetchClinics()
      onCloseModal()
    }).catch((error) => {
      Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
      onCloseModal()
    })
  } 

  const previousStep = () => {
    if (step2) {
      setStep1(true)
      setStep2(false)
    }
    if (step3) {
      setStep2(true)
      setStep3(false)
    }
  }

  const setIsLoading = () => {
    setLoading(!loading)
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            {loading &&
              <Col>
                <div className="loader-box">
                  <div className="loader-7"></div>
                </div>
              </Col>
            }
            {step1 &&
              <Step1
                formInfo={form1}
                setInfo={(data) => setInfo1(data)}
              />
            }
            {step2 &&
              <Step2
                formInfo={form2}
                setInfo={(data) => setInfo2(data)}
                previousStep={previousStep}
              />
            }
            {step3 &&
              <Step3
                formInfo={form3}
                setInfo={(data) => setInfo3(data)}
                acomodations={acomodations}
                previousStep={previousStep}
              />
            }
          </Row>
        </Container>
      </ModalBody>
    </div>
  )
}

export default CreateClinic