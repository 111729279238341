import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Input, Label, ListGroup, ListGroupItem, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { authHeader } from "../../../../services/fack.backend";

const ViewClinic = (props) => {
  const { onCloseModal, singleClinic } = props
  const [loading, setLoading] = useState(true)
  const [acomodations, setAcomodations] = useState([])

  const fetchAcomodations = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/clinicAcomodation/getAcomodations/` + singleClinic.id, { headers: authHeader() })
      .then(response => {
        setAcomodations(response.data)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchAcomodations()
  }, [])

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
      {"Visualizar Clínica"}
      </ModalHeader>
      <ModalBody>
        {loading ?
          <Col>
            <div className="loader-box">
              <div className="loader-7"></div>
            </div>
          </Col>
          :
          <Container>
            <Row>
              <Col>
                <Label className="col-form-label">{"Nome:"}</Label>
                <Input className="form-control" type="text" disabled={true} defaultValue={singleClinic.name} />
              </Col>
              <Col>
                <Label className="col-form-label">{"Telefone:"}</Label>
                <Input className="form-control" type="tel" disabled={true} defaultValue={singleClinic.phone} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label">{"Razão Social:"}</Label>
                <Input className="form-control" type="text" disabled={true} defaultValue={singleClinic.socialReason} />
              </Col>
              <Col>
                <Label className="col-form-label">{"Nome Fantasia:"}</Label>
                <Input className="form-control" type="text" disabled={true} defaultValue={singleClinic.fantasyName} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label">{"Email:"}</Label>
                <Input className="form-control" type="email" disabled={true} defaultValue={singleClinic.email} />
              </Col>
              <Col>
                <Label className="col-form-label">{"CNPJ:"}</Label>
                <Input className="form-control" type="text" maxLength="14" disabled={true} defaultValue={singleClinic.cnpj} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label">{"Inscrição Municipal:"}</Label>
                <Input className="form-control" type="text" disabled={true} defaultValue={singleClinic.neighborhoodSubscription} />
              </Col>
              <Col>
                <Label className="col-form-label">{"Inscrição Estadual:"}</Label>
                <Input className="form-control" type="text" disabled={true} defaultValue={singleClinic.stateSubscription} />
              </Col>

            </Row>
            <Row>
              <Col>
                <Label className="col-form-label">{"CEP:"}</Label>
                <Input className="form-control"
                  type="text"
                  disabled={true}
                  defaultValue={singleClinic.cep}
                />
              </Col>
              <Col>
                <Label className="col-form-label">{"Estado:"}</Label>
                <Input className="form-control"
                  type="text"
                  disabled={true}
                  defaultValue={singleClinic.state} />
              </Col>
              <Col>

                <Label className="col-form-label">{"Cidade:"}</Label>
                <Input className="form-control"
                  type="text"
                  disabled={true}
                  defaultValue={singleClinic.city}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label">{"Bairro:"}</Label>
                <Input className="form-control"
                  type="text"
                  disabled={true}
                  defaultValue={singleClinic.neighborhood}
                />
              </Col>
              <Col>
                <Label className="col-form-label">{"Endereço:"}</Label>
                <Input className="form-control"
                  type="text"
                  disabled={true}
                  defaultValue={singleClinic.address}
                />
              </Col>
              <Col>
                <Label className="col-form-label">{"Número:"}</Label>
                <Input className="form-control"
                  type="text"
                  disabled={true}
                  defaultValue={singleClinic.number}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label">{"Complemento:"}</Label>
                <Input className="form-control"
                  type="text"
                  disabled={true}
                  defaultValue={singleClinic.complement}
                />
              </Col>
              <Col>
                <Label className="col-form-label">{"Cód. Munícipio:"}</Label>
                <Input className="form-control"
                  type="text"
                  disabled={true}
                  defaultValue={singleClinic.neighborhoodCode}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label">{"Sigla:"}</Label>
                <Input className="form-control" type="text" disabled={true} defaultValue={singleClinic.initials} />
              </Col>
            </Row>
            <br />
            <Row>
              <Label className="form-label">{'Acomodações:'}</Label>
              <ListGroup>
                {acomodations.map(value => (
                  <ListGroupItem>{value.name}</ListGroupItem>
                ))}
              </ListGroup>
            </Row>
          </Container>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="danger" className="float-right" onClick={() => onCloseModal()}>Fechar</Button>
      </ModalFooter>
    </div>
  )
}

export default ViewClinic