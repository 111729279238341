import React, { useState, useEffect } from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import { Container, Row, Col, Form, Input, Label, Button } from 'reactstrap'
import { Password, EmailAddress, RememberPassword, ForgotPassword, CreateAccount, Login } from '../constant';
import { useNavigate } from 'react-router';
import { classes } from '../data/layouts';
import axios from 'axios';
import Swal from 'sweetalert2';

const Logins = (props) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [value, setValue] = useState(
    localStorage.getItem('profileURL' || man)
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );

  useEffect(() => {
    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
  }, [value, name]);

  const login = async (e) => {
    setLoading(true)
    const bodyData = {
      email: email,
      password: password
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/api/user/login`, bodyData).then(response => {
        if(response.data.token){
          setValue(man);
          setName(response.data.user.name);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('Name', response.data.user.name);
          window.location.href = `${process.env.PUBLIC_URL}/app/users/userTable/${layout}`;
        }
    }).catch(error => {
      setLoading(false)
      Swal.fire('Email ou Senha incorretos, verifique-os e tente novamente!', '', 'error');
    })
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <h4>Login</h4>
                  <p>{"Insira seu email & senha para fazer o login"}</p>
                  <div className="mb-3">
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                  </div>
                  <div className="mb-3 position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required="" />
                    <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                  </div>
                  <div className="login-btn mb-0">
                    <div className="checkbox ms-3">
                    </div><a className="link" href="#javascript">{ForgotPassword}</a>
                    <Button color="primary" onClick={() => login()}>{loading ? "Carregando..." : Login}</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

// export default withRouter(Logins);
export default Logins;