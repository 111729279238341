import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, ListGroup, ListGroupItem, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { authHeader } from "../../../../services/fack.backend";

const ViewPatient = (props) => {
  const { onCloseModal, singlePatient } = props

  const [linkedHealthInsurances, setLinkedHealthInsurances] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    fetchLinkedAcomodations();
  }, [])

  const fetchLinkedAcomodations = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/patientInsurance/getInsurances/${singlePatient.id}`, { headers: authHeader() })
      .then(res => {
        setLinkedHealthInsurances(res.data)
        setIsLoading(false)
      }).catch(err => {
        console.log(err)
      })
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        {"Visualizar Paciente"}
      </ModalHeader>
      {
        isLoading
          ?
          <div className="loader-box">
            < div className="loader-7" ></div >
          </div >
          :
          <div>
            <ModalBody>
              <Container>
                <Row>
                  <Col>
                    <Label className="col-form-label">{"Nome do Paciente:"}</Label>
                    <Input className="form-control" type="text" disabled={true} defaultValue={singlePatient.name} />
                  </Col>
                </Row>
                <br/>
                {linkedHealthInsurances.map(insurance => (
                  <div>
                    <Row>
                      <Col>
                        <Label className="col-form-label">{"Convênio:"}</Label>
                        <Input className="form-control" type="text" disabled={true} defaultValue={insurance.health_insurance.name} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label className="col-form-label">{"Matrícula:"}</Label>
                        <Input className="form-control" type="text" disabled={true} defaultValue={insurance.patient_insurance.enrollment} />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Label className="form-label">{'Acomodações:'}</Label>
                      <ListGroup>
                        {insurance.acomodations.map(value => (
                          <ListGroupItem>{value.description}</ListGroupItem>
                        ))}
                      </ListGroup>
                    </Row>
                    <br />
                  </div>
                ))
                }
              </Container>
            </ModalBody>
          </div>
      }
      <ModalFooter>
        <Button color="danger" className="float-right" onClick={() => onCloseModal()}>Fechar</Button>
      </ModalFooter>
    </div>
  )
}

export default ViewPatient;