import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, CardBody, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../../services/fack.backend";
import CreateSizeInsurance from "./form/create/createSizeInsurance";
import EditSizeInsurance from "./form/edit/editSizeInsurance";

const Sizes = (props) => {
  const { insurance_id } = props

  const [isLoading, setIsLoading] = useState(true)
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [sizes, setSizes] = useState({})
  const [singleSize, setSingleSize] = useState({})

  useEffect(() => {
    fetchSizes();
  }, [])

  const openCreate = () => {
    setIsOpenCreate(true)
  }

  const onCloseCreate = () => {
    setIsOpenCreate(false)
  }

  const openEdit = (size) => {
    setSingleSize(size)
    setIsOpenEdit(true)
  }

  const onCloseEdit = () => {
    setIsOpenEdit(false)
  }

  const fetchSizes = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/sizeInsurance/${insurance_id}`, { headers: authHeader() })
      .then(res => {
        setSizes(res.data)
        setIsLoading(false)
      })
  }

  const deleteSize = async (size) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o Porte ${size.size.description}`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/sizeInsurance/${size.id}`, { headers: authHeader() })
          .then(response => {
            Swal.fire(`Porte ${size.size.description} deletado com sucesso!`, '', 'success')
            fetchSizes()
          }).catch(error => {
            Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
          })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Button className="pull-right" color='primary' onClick={() => openCreate()}><i className="fa fa-plus"></i> Porte</Button>
        <Modal isOpen={isOpenCreate}>
          <CreateSizeInsurance
            onCloseModal={onCloseCreate}
            fetchSizes={fetchSizes}
            insurance_id={insurance_id}
          />
        </Modal>
        <Modal isOpen={isOpenEdit}>
          <EditSizeInsurance
            onCloseModal={onCloseEdit}
            fetchSizes={fetchSizes}
            size={singleSize}
          />
        </Modal>
        {isLoading ?
          <div className="loader-box">
            <div className="loader-7"></div>
          </div>
          :
          <CardBody>
            <FormGroup className="row">
              {sizes.map(size => (
                <Col className="col-4">
                  <Label className="col-sm-3 col-form-label">
                    {`Porte: ${size.size.description}`}
                  </Label>
                  <div className="input-group mb-3 col-6">
                    <Input className="form-control" type="text" disabled value={size.value} />
                    <span className="input-group-text"><i id="edit-button" className="fa fa-pencil" style={{ cursor: "pointer" }} onClick={() => openEdit(size)} /></span>
                    <span className="input-group-text"><i id="look-button" className="fa fa-trash" style={{ cursor: "pointer" }} onClick={() => deleteSize(size)}/></span>
                  </div>
                </Col>
              ))}
            </FormGroup>
          </CardBody>
        }
      </Container>
    </React.Fragment >
  )
}

export default Sizes;