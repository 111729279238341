import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Col, Container, Modal, Row, Table } from "reactstrap";
import { authHeader } from "../../services/fack.backend";
import CreateParticipationDegree from "./form/create/createParticipationDegree";
import EditParticipationDegree from "./form/edit/editParticipationDegree";
import Swal from "sweetalert2";

const ParticipationDegreeTable = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [degrees, setDegrees] = useState([])
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [singleDegree, setSingleDegree] = useState({})


  const onOpenCreateModal = () => {
    setIsOpenCreate(true)
  }

  const onCloseCreateModal = () => {
    setIsOpenCreate(false)
  }

  const onOpenEditModal = (degree) => {
    setSingleDegree(degree)
    setIsOpenEdit(true)
  }

  const onCloseEditModal = () => {
    setIsOpenEdit(false)
  }

  const fetchDegrees = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_API_URL}/api/participationDegree`, { headers: authHeader() })
      .then(res => {
        setDegrees(res.data)
        setIsLoading(false)
      })
  }

  const deleteDegree = async (degree) => {
    Swal.fire({
      title: 'Cuidado!',
      text: `Você deseja deletar o Grau de Participação ${degree.degree}`,
      icon: 'warning',
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/participationDegree/` + degree.id, { headers: authHeader() }).then(response => {
          Swal.fire('Procedimento Global deletado com sucesso!', '', 'success')
          fetchDegrees()
        }).catch(error => {
          Swal.fire('Ocorreu um erro, tente novamente!', '', 'error')
        })
      } else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  useEffect(() => {
    fetchDegrees()
  }, [])

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Modal isOpen={isOpenCreate} size="sm">
          <CreateParticipationDegree
            onCloseModal={onCloseCreateModal}
            fetchDegrees={fetchDegrees}
          />
        </Modal>

        <Modal isOpen={isOpenEdit} size="sm">
          <EditParticipationDegree
            onCloseModal={onCloseEditModal}
            fetchDegrees={fetchDegrees}
            singleDegree={singleDegree}
          />
        </Modal>
        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{"Graus de Participação"}</h5>
                    <span> {"Veja todos os Graus de Participação, crie, edite ou delete-os."}</span>
                    <Button className="pull-right" color='primary' onClick={() => onOpenCreateModal()}><i className="fa fa-plus"></i> Grau de Participação</Button>
                  </CardHeader>
                  {isLoading
                    ?
                    <div className="loader-box">
                      <div className="loader-7"></div>
                    </div>
                    :
                    <div className="table-responsive">
                      <Table className="table-border-horizontal">
                        <thead>
                          <tr>
                            <th scope="col">{"Grau"}</th>
                            <th scope="col">{"Porcentagem"}</th>
                            <th scope="col">{""}</th>
                            <th scope="col">{""}</th>
                          </tr>
                        </thead>
                        {degrees.map((value, key) => (
                          <tbody>
                            <tr key={key}>
                              <td key={key}>{value.degree}</td>
                              <td key={key}>{value.percentage}</td>
                              <td className="table-action-button">
                                <i id={`edit-button`} className="fa fa-pencil" onClick={() => onOpenEditModal(value)} style={{ cursor: "pointer" }}></i>
                                <i id={`delete-button`} className="fa fa-trash-o" onClick={() => deleteDegree(value)} style={{ cursor: "pointer" }}></i>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  }
                </Card>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ParticipationDegreeTable;