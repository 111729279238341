import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import { authHeader } from "../../../../../services/fack.backend";

const EditGlobalProcedure = (props) => {
  const { onCloseModal, fetchProcedures, singleProcedure } = props
  const [isLoadingInput, setIsLoadingInput] = useState(true)

  const [errors, setErrors] = useState({})
  const [code, setCode] = useState('')
  const [description, setDescription] = useState('')
  const [size, setSize] = useState('')
  const [sizes, setSizes] = useState('');


  useEffect(() => {
    fetchSizes();
  }, [])

  const fetchSizes = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/sizes`, { headers: authHeader() })
      .then(res => {
        setSizes(res.data)
        setIsLoadingInput(false)
      })
  }

  const createProcedure = async () => {
    const formData = {
      code: code === '' ? singleProcedure.code : code,
      description: description === '' ? singleProcedure.description : description,
      size_id: size === '' ? singleProcedure.size_id: size
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/api/globalProcedure/${singleProcedure.id}`, formData, { headers: authHeader() })
    .then(res => {
      onCloseModal()
      fetchProcedures()
      Swal.fire('Procedimento Global editado com sucesso!', '', 'success')
    }).catch(err => {
      onCloseModal()
      Swal.fire("Ocorreu um erro, tente novamente!", '', 'error');
    })
  }

  return (
    <div>
      <ModalHeader toggle={onCloseModal}>
        <h5>{'Editar Procedimento Global'}</h5>
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Form>
            <Label className="col-form-label">{"Código:"}</Label>
            <Input className="form-control" type="text" onChange={(e) => setCode(e.target.value)} defaultValue={singleProcedure.code}/>
            <span className="font-danger">{errors.name}</span>
            <Label className="col-form-label">{"Descrição:"}</Label>
            <Input className="form-control" type="text" onChange={(e) => setDescription(e.target.value)} defaultValue={singleProcedure.description}/>
            <span className="font-danger">{errors.type}</span>
            <Label className="col-form-label">{"Porte:"}</Label>
            {isLoadingInput
              ?
              <Col>
                <div className="loader-box">
                  <div className="loader-4"></div>
                </div>
              </Col>
              :
              <div>
                <Input className="form-control" type="select" onChange={(e) => setSize(e.target.value)}>
                  <option disabled selected value></option>
                  {sizes.map(size => (
                    singleProcedure.size_id == size.id ?
                    <option value={size.id} selected>{size.description}</option>
                    :
                    <option key={size.id} value={size.id}>{size.description}</option>
                  ))}
                </Input>
                <span className="font-danger">{errors.type}</span>
              </div>
            }
          </Form>
          <ModalFooter>
            <Button color="primary" className="float-right" onClick={() => createProcedure()}>{'Criar'}</Button>
          </ModalFooter>
        </Container>
      </ModalBody>
    </div>
  )

}

export default EditGlobalProcedure;